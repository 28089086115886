const emailFormInputConfig = {
  inputs: [
    {
      id: 'fromName',
      label: 'From Name',
      type: 'text',
      required: true,
      hasToggle: true,
      isHidden: true,
    },
    {
      id: 'replyTo',
      label: 'Reply To Email',
      type: 'email',
      required: true,
      hasToggle: true,
      isUser: true,
    },
    {
      id: 'to',
      label: 'To',
      type: 'div',
      required: true,
      disabled: true,
    },
    {
      id: 'ccAddresses',
      label: 'Cc',
      type: 'email',
      hasToggle: true,
      isHidden: true,
      isMulti: true,
      novalidate: true,
      isUser: true,
    },
    {
      id: 'bccAddresses',
      label: 'Bcc',
      type: 'email',
      hasToggle: true,
      isHidden: true,
      isMulti: true,
      novalidate: true,
      isUser: true,
    },
    {
      id: 'replyToMessageId',
      label: 'replyToMessageId',
      type: 'text',
      hasToggle: false,
      isHidden: true,
      isMulti: false,
      novalidate: true,
    },
    {
      id: 'subject',
      label: 'Subject Line',
      type: 'text',
      required: true,
    },
  ],
  message: {
    id: 'message',
    required: true,
    maxStringSize: 1000000,
  },
  attachments: {
    id: 'attachments',
    label: 'Attachments',
    hasToggle: true,
    isHidden: true,
    novalidate: true,
  },
};

export default emailFormInputConfig;
