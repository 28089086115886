import React from 'react';
import styled from 'styled-components';

const StyledHeader = styled.div`
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  font-weight: bold;
  text-align: left;
`;

const StyledWarning = styled.div`
  padding-bottom: 0.5rem;
  font-style: italic;
  text-align: center;
`;

const Icon = styled.div`
  background-color: ${(props) => props.colour || 'black'};
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  display: flex;
  padding: 0.5rem;

  svg {
    margin: auto;
    color: white;
    width: 18px;
    height: 18px;
    stroke-width: 2px;
  }
`;

const NodeItem = styled.div`
  background: ${(props) => (props.isDisabled ? 'grey' : 'inherit')};
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
  gap: 10px;
  display: flex;
  border: 1px solid #ccc;
  box-sizing: border-box;
  border-radius: 8px;
  padding-right: 12px;
`;

function getColour(type) {
  switch (type) {
    case 'startNode':
      return 'green';
    case 'actionNode':
      return '#0a1551';
    case 'waitNode':
      return 'purple';
    case 'decisionNode':
      return 'orange';
    case 'notesNode':
      return 'black';
    case 'endNode':
      return 'darkred';
    default:
      return 'black';
  }
}

const NodeAccordion = ({ nodes, header, isDisabled, warning }) => {
  return (
    <>
      {header && <StyledHeader>{header}</StyledHeader>}
      {nodes.map((node, index) => {
        const IconComp = node.icon;

        return (
          <NodeItem
            className="dndnode default"
            key={index}
            draggable={!isDisabled}
            onDragStart={(event) => {
              event.dataTransfer.setData('application/reactflow', node.type);
              event.dataTransfer.setData('actionType', node.actionType);
            }}
            isDisabled={isDisabled}
          >
            <Icon colour={getColour(node.type)}>{IconComp ? <IconComp /> : null}</Icon>
            {node.label}
          </NodeItem>
        );
      })}
    </>
  );
};

export default NodeAccordion;
