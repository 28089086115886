import React, { Fragment, useEffect, useState } from 'react';
import { Confirmation } from '../../../../../../Base/Modal';
import Select from 'react-select';
import { FormGroup, Label, Input, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Action from './ActionNode';
import { CreateButton, CancelButton, DeleteButton } from '../../../../../../Base/Buttons';
import InputNode from './InputNode';
import ConditionalGroupBox from './DecisionNode/ConditionalGroupBox';
import styled from 'styled-components';
import { actionNodes } from '../../NodeTypesLists';

const StyledTextArea = styled(Input)`
  min-height: 250px !important;
`;

const StyledNodeContent = styled.div`
  background: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
`;

const StyledModal = styled(Modal)`
  .modal-dialog {
    overflow-y: initial !important;
  }
  .modal-content {
    overflow: visible;
  }

  .modal-body {
    overflow: visible;
  }
`;

const WaitTypes = [
  { label: 'Minutes', value: 'min' },
  { label: 'Hours', value: 'hour' },
  { label: 'Days', value: 'day' },
  { label: 'Weeks', value: 'week' },
];

function NodeContent({ selectedNode, updateNode, value }) {
  if (selectedNode.type === 'notesNode') {
    return (
      <FormGroup>
        <Label>Notes</Label>
        <StyledTextArea
          name="text"
          type="textarea"
          value={value.label}
          onChange={(e) => {
            updateNode({ label: e.target.value });
          }}
        />
      </FormGroup>
    );
  }

  if (selectedNode.type === 'waitNode') {
    return (
      <FormGroup>
        <Label>
          Choose Period to Wait<span className="required">*</span>
        </Label>
        <FormGroup row>
          <Col>
            <Input
              id="number"
              type="number"
              min="0"
              value={value?.number}
              onChange={(e) => updateNode({ ...value, number: e.target.value })}
            />
          </Col>
          <Col>
            <Select
              className="react-select"
              placeholder="Select Range"
              options={WaitTypes}
              styles={{
                container: (baseStyles) => ({
                  ...baseStyles,
                  marginTop: '-0.05em',
                }),
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              }}
              menuPortalTarget={document.body}
              value={WaitTypes.find((a) => a.value === value?.range)}
              onChange={(val) => {
                updateNode({ ...value, range: val.value });
              }}
            />
          </Col>
        </FormGroup>
      </FormGroup>
    );
  }

  if (selectedNode.type === 'startNode') {
    return <InputNode selectedNode={value} onChange={(updateValue) => updateNode(updateValue)} />;
  }

  if (selectedNode.type === 'decisionNode') {
    return <ConditionalGroupBox selectedNode={value} onChange={(updateValue) => updateNode(updateValue)} />;
  }

  if (selectedNode.type === 'actionNode') {
    return <Action option={value} setOption={(updateValue) => updateNode(updateValue)} />;
  }
}

function EditNodeModal({ isOpen, toggle, selectedNode, onDelete, onSave }) {
  const [showConfirm, setShowConfirm] = useState(false);
  const [value, setValue] = useState(selectedNode?.data);

  useEffect(() => {
    setValue(selectedNode?.data);
  }, [isOpen, selectedNode?.data]);

  const handleSave = (e) => {
    e.preventDefault();
    onSave(value);
  };

  const handleCancel = () => {
    setValue(selectedNode?.data);
    toggle();
  };

  const handleClose = () => {
    if (JSON.stringify(value) !== JSON.stringify(selectedNode?.data)) {
      setShowConfirm(true);
    } else {
      toggle();
    }
  };

  const confirmClose = () => {
    setShowConfirm(false);
    handleCancel();
  };

  const label = actionNodes.find((node) => node.actionType === selectedNode?.data?.actionType)?.label;

  return (
    <Fragment>
      <StyledModal isOpen={isOpen} toggle={handleClose} centered backdrop="static">
        <ModalHeader toggle={handleClose}>Edit {label}</ModalHeader>
        <ModalBody>
          <StyledNodeContent>
            <NodeContent
              selectedNode={{ ...selectedNode }}
              value={value}
              updateNode={(updatedValue) => {
                setValue({
                  ...value,
                  ...updatedValue,
                });
              }}
            />
          </StyledNodeContent>
        </ModalBody>
        <ModalFooter>
          <div className="d-flex ms-auto gap-2">
            <DeleteButton label="Delete Node" action={() => onDelete()} floatRight={false} />
            <CreateButton label="Save" floatRight={false} action={handleSave} />
            <CancelButton floatRight={false} action={handleCancel} />
          </div>
        </ModalFooter>
      </StyledModal>
      <Confirmation
        content="Your data will be lost if you close the modal"
        show={showConfirm}
        cancelCallback={() => setShowConfirm(false)}
        confirmCallback={confirmClose}
      />
    </Fragment>
  );
}

export default EditNodeModal;
