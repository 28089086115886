import axios from 'axios';
import config from '../config/config';
import { getCommonHeaders } from './common-api-utils';

const {
  api: { middlewareAPIURL },
  permissions: { permissionGroupsPath, permissionsPath },
} = config;

export async function listAllPermissionGroups() {
  const result = await axios.get(`${middlewareAPIURL}${permissionGroupsPath}`, { headers: getCommonHeaders() });

  if (result.status === 200 || result.status === 201) return result.data;

  return null;
}

export async function updatePermissionGroup(data = {}, id) {
  if (Object.keys(data).length) {
    const result = await axios.put(
      `${middlewareAPIURL}${permissionGroupsPath}/${id}`,
      { ...data },
      { headers: getCommonHeaders() },
    );

    if (result.status === 200 || result.status === 201) return result.data;
  }

  return null;
}

export async function createPermissionGroup(data = {}) {
  if (Object.keys(data).length) {
    const result = await axios.post(
      `${middlewareAPIURL}${permissionGroupsPath}`,
      { ...data },
      { headers: getCommonHeaders() },
    );

    if (result.status === 200 || result.status === 201) return result.data;
  }

  return null;
}

export async function deletePermissionGroup(id) {
  if (id) {
    const result = await axios.delete(`${middlewareAPIURL}${permissionGroupsPath}/${id}`, {
      headers: getCommonHeaders(),
    });

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}

export async function listAllPermissions() {
  const result = await axios.get(`${middlewareAPIURL}${permissionsPath}`, { headers: getCommonHeaders() });

  if (result.status === 200 || result.status === 201) return result.data;
  return null;
}
