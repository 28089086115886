import React, { useEffect, useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Row, Col, Button } from 'reactstrap';
import IconSVG from '../../../Base/SVG/IconSVG';
import { toast } from 'react-toastify';
import moment from 'moment';
import ApplicationInfo from './ApplicationInfo';
import { retryableAPICall } from '../../../../api/common-api-utils';
import { downloadApplicationPDF, getApplicant, moveCandidate } from '../../../../api/CandidateAPI/CandidateAPI';
import { downloadFiles } from '../../../../api/FileAPI/FileAPI';
import Attachment from './Attachment';
import MappedAnswers from './MappedAnswers';
import Can from '../../../Base/RBAC/Can/Can';
import { ActionButton, EditButton } from '../../../Base/Buttons';
import { getEditFormToken } from '../../../../api/FormsAPI/ATSFormsAPI';
import { FormModal, ModalPopup } from '../../../Base/Modal';
import config from '../../../../config/config';
import CandidateDetailsContext from './CandidateDetailsContext';
import { useOutsideClick } from '../../../Base/hooks';
import { HeadingPushRight } from '../../../Base/Headings';
import VacancySelect from '../../../Base/Forms/Custom/VacancySelect/VacancySelect';
import { requestStatuses } from '../../../../js/constants/requestStatuses';
import { CandidateForwardModal } from '../CandidateTable';
import PercentageBar from './PercentageBar';
import debounce from 'lodash.debounce';

const { web } = config;
const RestrictedAttachment = Can(Attachment);

async function updateAnswers(applicantId, onSuccess = () => {}) {
  const resp = await retryableAPICall(() => getApplicant(applicantId));

  if (typeof resp === 'string') {
    toast.error('Error updating application');
  } else {
    const { mappedAnswers = {}, applicantProfile = {} } = resp;
    onSuccess(mappedAnswers, applicantProfile);
  }
}

async function generateToken(formId, onSuccess = () => {}) {
  if (formId) {
    const resp = await retryableAPICall(() => getEditFormToken(formId));

    if (typeof resp === 'string') {
      toast.error('Error generating token');
    } else {
      const { token } = resp;
      if (token) onSuccess(token);
    }
  }
}

// In an application form we need to ask diversity questions but they can't be
// shown on the candidate slider. If a section has a diversity question we assume the
// whole section needs to go
// TO_DO: This is a temporary solution, we need to have a better way to handle this
function removeDiversityQuestions(mappedAnswers) {
  const updatedSections = mappedAnswers?.sections.reduce((acc, section) => {
    const filteredQuestions =
      section?.questions?.length > 0
        ? section.questions[0].filter((question) => {
            return question.fieldId.toUpperCase().includes('DIVERSITY');
          })
        : [];

    if (filteredQuestions.length === 0) {
      acc.push(section);
    }

    return acc;
  }, []);

  return { sections: updatedSections };
}

export default function CandidateDetails({
  vacancyTitle,
  vacancyAddress,
  accountName,
  applicant,
  onUpdate,
  onMove,
  embedded,
  isTouch,
  // activeAccountId,
}) {
  const [isDownloading, setIsDownloading] = useState(false);
  const [modalConfig, setModalConfig] = useState({ isOpen: false, url: '' });
  const [isMoveModalOpen, setIsMoveModalOpen] = useState(false);
  const [applicationAnswers, setApplicationAnswers] = useState({});
  const [applicationAttachments, setApplicationAttachments] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [moveToVacancy, setMoveToVacancy] = useState({});
  const wrapperRef = useRef();
  const { onDetailsChange, onCandidateMove } = useContext(CandidateDetailsContext);
  const [vacTitle, setVacTitle] = useState('');
  const [vacAddress, setVacAddress] = useState('');
  const [isForwardModalOpen, setIsForwardModalOpen] = useState(false);
  const containerRef = React.useRef(null);

  const [clamped, setClamped] = useState(true);
  const [showButton, setShowButton] = useState(true);

  const handleClick = () => setClamped(!clamped);

  useOutsideClick(
    wrapperRef,
    () => {
      setIsMenuOpen(false);
    },
    '.slide-pane.candidate-slider',
  );

  const {
    applicantProfile: { attachments = [] },
    id,
    applicationFormMetaData: { completedFormId },
    mappedAnswers,
    vacancyId,
  } = applicant;

  useEffect(() => {
    const hasClamping = (el) => {
      const { clientHeight, scrollHeight } = el;
      return clientHeight !== scrollHeight;
    };

    const checkButtonAvailability = () => {
      if (containerRef.current) {
        // Save current state to reapply later if necessary.
        const hadClampClass = containerRef.current.classList.contains('clamp');
        // Make sure that CSS clamping is applied if aplicable.
        if (!hadClampClass) containerRef.current.classList.add('clamp');
        // Check for clamping and show or hide button accordingly.
        setShowButton(hasClamping(containerRef.current));
        // Sync clamping with local state.
        if (!hadClampClass) containerRef.current.classList.remove('clamp');
      }
    };

    const debouncedCheck = debounce(checkButtonAvailability, 50);

    checkButtonAvailability();
    window.addEventListener('resize', debouncedCheck);

    return () => {
      window.removeEventListener('resize', debouncedCheck);
    };
  }, [containerRef]);

  useEffect(() => {
    setApplicationAnswers(removeDiversityQuestions(mappedAnswers));
    setApplicationAttachments(attachments || []);
    setVacTitle(vacancyTitle);
    setVacAddress(vacancyAddress?.formattedAddress);
  }, [attachments, mappedAnswers, vacancyTitle, vacancyAddress]);

  async function doDownload() {
    setIsDownloading(true);
    await retryableAPICall(() => downloadApplicationPDF(id));
    setIsDownloading(false);
  }

  async function doDownloadCV(fileId) {
    setIsDownloading(true);
    await retryableAPICall(() => downloadFiles([fileId]));
    setIsDownloading(false);
  }

  function handleEdit() {
    generateToken(completedFormId, (token) => {
      const qStr = `?token=${token}&atsApply=true${isTouch ? '&quickApply=true' : ''}`;

      setModalConfig({
        isOpen: true,
        url: `${web.formsWebUrl}${qStr}`,
      });
    });
  }

  async function handleMoveCandidate() {
    if (Object.keys(moveToVacancy).length) {
      const { value: moveToVacId, label: moveToVacTitle } = moveToVacancy;
      const resp = await moveCandidate(id, moveToVacId);

      if (typeof resp === 'string') {
        let reasonStr = '';
        if (resp === requestStatuses.NOT_FOUND_ERROR) {
          reasonStr = '. Could not find vacancy.';
        } else if (resp === requestStatuses.PERMISSION_DENIED) {
          reasonStr = '. You do not have permission to move candidate.';
        }

        toast.error(`Error moving candidate to vacancy${reasonStr}`);
      } else {
        toast.success('Candidate moved successfully');
        setIsMoveModalOpen(false);

        if (embedded) {
          onMove();
          window.open(`/vacancy/view?id=${moveToVacId}&action=openSlider&actionId=${id}`);
        } else {
          // titles are Title sent as (location - accountName) this is no good for title changing
          // get all strings before any occurences of parenthesis
          const match = moveToVacTitle.match(/^[^(]+/g);
          let updatedVacTitle = moveToVacTitle;
          // if there is match trim the captured string
          if (match) updatedVacTitle = match[0].trim();

          const updatedVacDetails = {
            vacancyId: moveToVacId,
            title: updatedVacTitle,
          };
          onCandidateMove(id, updatedVacDetails);
          setVacTitle(updatedVacTitle);
        }
      }
    }
  }

  const applicationExpirationDate = moment(applicant.appliedOnDate).add(1, 'year');
  const isFormEditDisabled = moment().isAfter(applicationExpirationDate);

  const menuItems = [
    {
      action: () => {
        doDownload();
        setIsMenuOpen(false);
      },
      id: 'download',
      label: 'Download Application',
      permissions: ['candidate:view'],
    },
  ];

  if (completedFormId) {
    menuItems.push(
      {
        action: () => {
          handleEdit();
          setIsMenuOpen(false);
        },
        id: 'edit',
        isDisabled: isFormEditDisabled,
        label: 'Edit Application',
        permissions: ['candidate:update'],
        tooltipContent: 'You are unable to edit records that are over 12 months old',
      },
      {
        action: () => {
          const cv = applicationAttachments.find((attachment) => attachment.attachmentType === 'CV');

          if (cv && cv.id) {
            doDownloadCV(cv.id);
          } else {
            toast.error('Could not find CV, please try again');
          }
          setIsMenuOpen(false);
        },
        id: 'downloadCv',
        label: 'Download CV',
        permissions: ['candidate:attachments:read'],
      },
      {
        action: () => {
          setIsMoveModalOpen(true);
          setIsMenuOpen(false);
        },
        id: 'moveCandidate',
        label: 'Move Candidate',
        permissions: ['candidate:update'],
      },
      {
        action: () => {
          setIsForwardModalOpen(true);
          setIsMenuOpen(false);
        },
        id: 'forwardCandidateApplication',
        label: 'Forward Candidate',
        permissions: ['candidate:view'],
      },
    );
  }
  const skills = applicant.skills || [];

  const matchingSkills = ['Customer Service', 'Event Planning', 'Communication', 'Hospitality Management', 'Catering'];
  const matchingSkillsCount = skills.filter((skill) => matchingSkills.includes(skill.name)).length;

  return (
    <>
      <Card className="application-details">
        <CardBody>
          <Row>
            <Col>
              <HeadingPushRight headingAs="h4" headingText="Application Details">
                {isTouch ? (
                  <EditButton action={handleEdit} floatRight={false} size="sm" />
                ) : (
                  <div ref={wrapperRef}>
                    <ActionButton
                      buttonLabel={isDownloading ? 'Downloading' : 'Actions'}
                      className="ellipsis-opts-btn"
                      iconName="Ellipsis"
                      isOpen={isMenuOpen}
                      menuItems={menuItems}
                      onToggle={(isOpen) => setIsMenuOpen(isOpen)}
                      pauseNotificationsOnOpen
                    />
                  </div>
                )}
              </HeadingPushRight>
            </Col>
          </Row>
          <Row>
            <Col>
              <ApplicationInfo
                accountName={accountName}
                data={applicant}
                isTouch={isTouch}
                vacancyTitle={vacTitle}
                vacancyAddress={vacAddress}
              />
            </Col>
          </Row>
          <hr className="my-1" />
          {applicant.candidateSummary && (
            <Row style={{ marginTop: '10px' }}>
              <Col>
                <Row>
                  <Col>
                    <h4>Candidate Summary</h4>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p style={{ whiteSpace: 'pre-line' }}>{applicant.candidateSummary}</p>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          {skills && skills.length > 0 && (
            <Row>
              <Col>
                <Row>
                  <Col>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px', marginBottom: '0px' }}>
                      <h4>Skills</h4>
                      <PercentageBar percentage={(matchingSkillsCount / skills.length) * 100} />
                    </div>
                  </Col>
                  <Col className="d-flex justify-content-end">
                    {showButton && (
                      <Button color="link" onClick={handleClick} className="text-right pe-2">
                        {clamped ? (
                          <>
                            <IconSVG name="Chevron" width="2em" /> View more
                          </>
                        ) : (
                          <>
                            <IconSVG name="Chevron Up" width="2em" /> View less
                          </>
                        )}
                      </Button>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col style={{ marginLeft: '-3px' }}>
                    <div ref={containerRef} className={`${clamped ? 'clamp' : ''}`}>
                      {skills.map((skill) => (
                        <span
                          key={skill.name}
                          className={`skill-pill ${matchingSkills.includes(skill.name) ? 'matched' : ''} ${
                            skill.totalExp > 0 ? 'experience' : ''
                          }`}
                          title={`${skill.totalExp} months experience`}
                        >
                          {skill.name}
                          {skill.totalExp > 0 && (
                            <span className="months-experience">
                              {skill.totalExp > 99 ? '99+' : skill.totalExp} Mths
                            </span>
                          )}
                        </span>
                      ))}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          <Row>
            <Col className="mapped-answers" sm="7">
              <MappedAnswers
                answers={applicationAnswers}
                attachments={applicationAttachments}
                candidateId={applicant.id}
                isTouch={isTouch}
                titleWidth={5}
                touchTitleColumnSize={5}
              />
            </Col>
            <Col className="cv-attachment">
              <RestrictedAttachment
                applicantId={applicant.id}
                data={applicationAttachments.find((att) => att.attachmentType === 'CV')}
                isTouch={isTouch}
                permissions={['candidate:attachments:read']}
                title="CV Received"
                titleWidth={5}
                touchTitleColumnSize={5}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      {modalConfig.isOpen && (
        <FormModal
          contentUrl={modalConfig.url}
          id="candidate-details"
          isOpen={modalConfig.isOpen}
          title={`Application for ${vacancyTitle}`}
          toggle={(closeMethod, isOpen, frameId) => {
            if (closeMethod === 'IFRAME' && isOpen && frameId === 'candidate-details') {
              updateAnswers(id, (updatedAnswers, updatedProfile) => {
                onUpdate(updatedProfile, updatedAnswers);
                setModalConfig({ isOpen: false, url: '' });
                onDetailsChange(id, updatedProfile);
              });
            } else {
              setModalConfig({ isOpen: false, url: '' });
            }
          }}
          warnOnClickClose
        />
      )}
      <ModalPopup
        allowOverflow
        closeOnOkay={false}
        isOpen={isMoveModalOpen}
        onOkay={handleMoveCandidate}
        onToggle={(isOpen) => {
          setIsMoveModalOpen(isOpen);
          setMoveToVacancy({});
        }}
        title="Select Vacancy To Move Candidate To"
      >
        <VacancySelect
          activePendingOnly
          currentVacancyId={vacancyId}
          id="moveCandidateSelect"
          ignoreCurrentVacancy
          onChange={(val, selectedObj) => setMoveToVacancy(selectedObj)}
          value={moveToVacancy.value || ''}
          // activeAccountId={activeAccountId}
        />
      </ModalPopup>
      <CandidateForwardModal
        buttonLabel="Forward Candidate"
        candidateIds={[id]}
        isOpen={isForwardModalOpen}
        onClose={() => setIsForwardModalOpen(false)}
        title="Forward Candidate Application"
        vacancyId={vacancyId}
      />
    </>
  );
}

CandidateDetails.propTypes = {
  vacancyTitle: PropTypes.string,
  accountName: PropTypes.string,
  vacancyAddress: PropTypes.string,
  applicant: PropTypes.shape({
    id: PropTypes.string,
    applicantProfile: PropTypes.shape(),
    applicationFormMetaData: PropTypes.shape({
      completedFormId: PropTypes.string,
    }),
    mappedAnswers: PropTypes.shape(),
    vacancyId: PropTypes.string,
  }),
  onUpdate: PropTypes.func,
  onMove: PropTypes.func,
  embedded: PropTypes.bool,
  isTouch: PropTypes.bool,
  // activeAccountId: PropTypes.string,
};

CandidateDetails.defaultProps = {
  vacancyTitle: null,
  vacancyAddress: null,
  accountName: null,
  applicant: null,
  onUpdate: () => {},
  onMove: () => {},
  embedded: false,
  isTouch: false,
  // activeAccountId: null,
};
