import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CancelButton, CreateButton } from '../../../Base/Buttons';
import { Form, Input } from 'reactstrap';
import { FormGroup } from '../../../Base/Forms/Custom/CommonComponents';
import { useFormKeypress } from '../../../Base/hooks';
import { requestStatuses } from '../../../../js/constants/requestStatuses';
import { toast } from 'react-toastify';
import { updatePermissionGroup, createPermissionGroup } from '../../../../api/PermissionsAPI';
import { retryableAPICall } from '../../../../api/common-api-utils';
import { trimFormData } from '../../../../js/utils/general-utils';
import validation, { mapErrors } from '../../../../js/utils/validation';
import PermissionsTable from '../../../Base/Forms/Custom/Permissions/PermissionsTable';

const defaultClientData = {
  name: '',
  permissions: [],
  importance: 0,
};

function PermissionsGroupEditor({ isEditing, data, onSave, onCancel }) {
  const formRef = useFormKeypress();
  const [isSaving, setIsSaving] = useState(false);
  const [clientData, setClientData] = useState({ ...defaultClientData });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (isEditing) {
      setClientData(data);
    }
  }, [data, isEditing]);

  function handleChange(id, value) {
    let updated = { ...clientData, [id]: value };

    setClientData(updated);
  }

  async function handleSave() {
    setIsSaving(true);

    const trimmedData = trimFormData(clientData);
    setClientData(trimmedData);

    const baseErrObj = validation(
      [
        { id: 'name', required: true },
        { id: 'importance', required: true },
      ],
      trimmedData,
    );

    const { messages, hasErrors } = mapErrors(baseErrObj);
    setErrors(messages);

    if (!hasErrors) {
      const serverData = {
        name: trimmedData.name,
        permissions: trimmedData.permissions,
        importance: Number(trimmedData.importance),
      };

      let resp = null;

      if (clientData.id) {
        resp = await retryableAPICall(() => updatePermissionGroup(serverData, data.id));
      } else {
        resp = await retryableAPICall(() => createPermissionGroup(serverData));
      }

      if (typeof resp === 'string') {
        let reasonStr = '';
        if (isEditing) {
          if (resp === requestStatuses.NOT_FOUND_ERROR) {
            reasonStr = '. Could not update Permission Group as it does not exist';
          } else if (resp === requestStatuses.PERMISSION_DENIED) {
            reasonStr = '. You do not have permission to update this Permission Group';
          }
        }

        if (resp === requestStatuses.ALREADY_EXISTS_ERROR) {
          reasonStr = '. Unable to create Permission Group as this name or importance level has already been used';
        }

        toast.error(`Error ${isEditing ? 'updating' : 'creating'} Permission Group ${reasonStr}`);
      } else {
        toast.success(`Permission Group successfully ${isEditing ? 'updated' : 'created'}`);
        onSave(resp);
      }
    }

    setIsSaving(false);
  }

  const saveBtnText = isSaving ? 'Saving...' : `${isEditing ? 'Update' : 'Create'} Permission Group`;

  const updateRoles = (role, checked) => {
    const { permissions } = clientData;
    const updatedAuthorisers = checked
      ? [...permissions, role]
      : permissions.filter((permissions) => permissions !== role);

    setClientData({ ...clientData, permissions: updatedAuthorisers });
  };

  return (
    <Form
      className="permission-group-editor"
      innerRef={formRef}
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <FormGroup error={errors.name} id="name" label="Name" required>
        <Input
          id="name"
          onChange={(e) => handleChange('name', e.target.value)}
          type="text"
          value={clientData.name || ''}
        />
      </FormGroup>
      <FormGroup error={errors.importance} id="importance" label="Importance" required>
        <Input
          id="importance"
          onChange={(e) => handleChange('importance', e.target.value)}
          type="number"
          value={clientData.importance}
        />
      </FormGroup>
      <FormGroup id="permissions" label="Permissions" required>
        <PermissionsTable updateRoles={updateRoles} permissions={clientData.permissions} />
      </FormGroup>
      <CreateButton action={handleSave} disabled={isSaving} isLoading={isSaving} label={saveBtnText} />
      <CancelButton action={onCancel} disabled={isSaving} label="Cancel" />
    </Form>
  );
}

PermissionsGroupEditor.propTypes = {
  activeAccountId: PropTypes.string,
  activeAccountName: PropTypes.string,
  data: PropTypes.shape(),
  isEditing: PropTypes.bool,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  totalAccounts: PropTypes.number,
};

PermissionsGroupEditor.defaultProps = {
  activeAccountId: undefined,
  activeAccountName: undefined,
  data: {},
  isEditing: false,
  onCancel: () => {},
  onSave: () => {},
  totalAccounts: 0,
};

export default PermissionsGroupEditor;
