import React, { useState, useEffect } from 'react';
import VacancyDetail from './VacancyDetail';
import ResponseBanner from './ResponseBanner';
import ActionButton from './ActionButton';
import styled from 'styled-components';
import queryString from 'query-string';
import { Loader } from '../../../views/Base/Loading';
import { useLocation } from 'react-router-dom';

import { retryableAPICall } from '../../../api/common-api-utils';

import { getATR } from '../../../api/ATRAPI/ATRAPI';

const StyledContainer = styled.div`
  padding: 1rem;

  margin: auto;
  margin-top: 1rem;
  max-width: 1500px;
`;

const StyledLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const StyledActions = styled.div`
  display: flex;
  justify-content: space-between;
  margin: auto;
  margin-bottom: 1rem;

  gap: 1rem;
  span {
    margin-bottom: auto;
    margin-top: auto;
  }
`;
const ATR = () => {
  const [data, setData] = useState(null);

  const { search } = useLocation();

  const { id } = queryString.parse(search);

  useEffect(() => {
    const loadData = async () => {
      const result = await retryableAPICall(() => getATR(id));
      setData(result);
    };

    loadData();
  }, []);

  if (!data)
    return (
      <StyledLoader>
        <Loader />
      </StyledLoader>
    );

  const { name, vacancy, status, statusUpdatedDateTime } = data;
  const isResponded =
    status === 'JUST_APPROVED' || status === 'APPROVED' || status === 'REJECTED' || status === 'JUST_REJECTED';

  const handleSubmit = (approved) => {
    setData({
      ...data,
      status: approved ? 'JUST_APPROVED' : 'JUST_REJECTED',
    });
  };

  return (
    <StyledContainer>
      <h1>Hello {name},</h1>
      <StyledActions>
        <span>
          Your approval has been requested before it's able to be published. Please review the details and click
          approve/reject.
        </span>
        <ActionButton onSubmit={handleSubmit} disabled={!isResponded} id={id} />
      </StyledActions>
      {isResponded && <ResponseBanner statusUpdateDateTime={statusUpdatedDateTime} status={status} />}
      <VacancyDetail vacancy={vacancy} />
      <ActionButton onSubmit={handleSubmit} disabled={!isResponded} id={id} />
    </StyledContainer>
  );
};

export default ATR;
