import axios from 'axios';
import fileDownload from 'js-file-download';
import { getCommonHeaders } from '../common-api-utils';
import config from '../../config/config';

const { file, api } = config;

/**
 * Allows the downloading of the application in PDF format
 * @param fileName
 * @returns {Promise<*>}
 */
// eslint-disable-next-line import/prefer-default-export
export async function downloadFiles(fileIds, opts = {}) {
  const { forceDownload = true, base64 = false } = opts;

  const result = await axios.post(`${api.middlewareAPIURL}${file.getFile}`, fileIds, { headers: getCommonHeaders() });

  if (result.status === 200 && result.data && Array.isArray(result.data) && result.data.length) {
    const blobs = await Promise.all(
      result.data.map(async (newFile) => {
        const {
          content: { contentType, content },
        } = newFile;

        if (base64) return content;

        const base64Str = await fetch(`data:${contentType};base64,${content}`);
        return base64Str.blob();
      }),
    );

    if (forceDownload) {
      blobs.forEach((blob, i) => fileDownload(blob, result.data[i].content.fileName));
      return null;
    }

    return blobs;
  }

  return null;
}

export async function downloadFileData(fileIds) {
  const result = await axios.post(`${api.middlewareAPIURL}${file.getFile}`, fileIds, { headers: getCommonHeaders() });

  if (result.status === 200 && result.data && Array.isArray(result.data) && result.data.length) {
    return result.data.map((fle) => {
      return `data:${fle.content.contentType};base64,${fle.content.content}`;
    });
  }

  return null;
}

export async function getVideoVTT(fileId, lang = 'en') {
  const result = await axios.get(`${api.middlewareAPIURL}${file.getVTTFile}/${fileId}/${lang}`, {
    headers: getCommonHeaders(),
  });

  if (result.status === 201 || result.status === 200) {
    return result.data;
  }
  return null;
}

export async function downloadLargeFile(fileId, opts = {}) {
  const { forceDownload = true, base64 = false } = opts;

  const result = await axios.get(`${api.middlewareAPIURL}${file.getLargeFile}${fileId}`, {
    headers: getCommonHeaders(),
    responseType: 'blob',
    maxRedirects: 0,
  });

  if (result.status === 200 && result.data) {
    const blob = result.data;

    // Now we can access the headers
    const contentDisposition = result.headers['content-disposition'];

    if (forceDownload) {
      let filename = 'download'; // Default filename

      // Parse the filename from content-disposition if available
      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(/filename="?([^"]+)"?/i);
        if (filenameMatch) {
          filename = filenameMatch[1];
        }
      }

      fileDownload(blob, filename);
      return null;
    }

    // Return the blob if not downloading
    return blob;
  }

  return null;
}
