import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import Can from '../../../Base/RBAC/Can/Can';
import { ClientAdminNoDataMessage, ClientAdminSlider, utils, PageColumn } from '../Dashboard';
import { useMounted, usePageLoading } from '../../../Base/hooks';
import { DataTable, useTableState } from '../../../Base/Tables';
import { ActionButton, CreateButton } from '../../../Base/Buttons';
import { TippyTruncate } from '../../../Base/Truncate';
import { Confirmation } from '../../../Base/Modal';
import { deleteObjFromArray, updateObjInArray, addObjToArray } from '../../../../js/utils/arrayOfObjects';
import { retryableAPICall } from '../../../../api/common-api-utils';
import { getAdminATR, deleteATR } from '../../../../api/ATRAPI/ATRAPI';
import { checkPermissions } from '../../../../js/auth/AuthUtils';
import { Input, Card, CardBody } from 'reactstrap';
import EditPanel from './EditPanel';
import EnhancedCard from '../Common/EnhancedCard';

const RestrictedCreateButton = Can(CreateButton);

const StyledTable = styled(DataTable)`
  .rt-thead {
    background-color: #f8f9fa !important;
    padding: 0.35rem 0rem;
  }
`;

const SearchInput = styled(Input)`
  border-radius: 8px;
  border: 1px solid #ced4da;
  padding-left: 1rem;
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
`;

const NoDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  text-align: center;
`;

const IconWrapper = styled.div`
  background-color: #f0f4f8;
  border-radius: 50%;
  padding: 1.5rem;
  margin-bottom: 1rem;
`;

const MessageText = styled.p`
  color: #64748b;
  margin-bottom: 1.5rem;
`;

const NoDataState = ({ searchTerm, onCreateATR }) => {
  const isFiltered = searchTerm && searchTerm.length > 0;

  return (
    <NoDataContainer>
      <IconWrapper>
        {isFiltered ? <Search size={48} color="#3b82f6" /> : <FileText size={48} color="#3b82f6" />}
      </IconWrapper>
      <h4>{isFiltered ? 'No ATRs match the filter' : 'No ATRs set up yet'}</h4>
      <MessageText>
        {isFiltered
          ? `We couldn't find any ATRs matching "${searchTerm}". Try adjusting your search terms.`
          : "You haven't created any Authorisations to Recruit yet. Get started by creating your first ATR."}
      </MessageText>
      {!isFiltered && (
        <Button onClick={onCreateATR}>
          <Plus className="mr-2 h-4 w-4" /> Create your first ATR
        </Button>
      )}
    </NoDataContainer>
  );
};

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

async function deleteAction(id, onSuccess = () => {}, onError = () => {}) {
  if (id) {
    const resp = await retryableAPICall(() => deleteATR(id));

    if (typeof resp === 'string' && resp.length) {
      onError();
    } else {
      onSuccess();
    }
  }
}

function AuthorisationToRecruit({ isCreateMode, onClose, totalAccounts, activeAccountId, tablePrefs }) {
  const isMounted = useMounted();
  const { pageState, setPageResolved, setPageRejected } = usePageLoading();
  const { tableState, setTableData, setTableRejected } = useTableState({ rowsPerPage: tablePrefs.pageSize });
  const [showConfirm, setShowConfirm] = useState(false);
  const [actionMenuId, setActionMenuId] = useState('');
  const [deleteObj, setDeleteObj] = useState();
  const [ATRObj, setATRObj] = useState({});
  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const [isEditing, setIsEditing] = useState(false);

  const requestData = useCallback(
    async (callback = () => {}) => {
      const resp = await retryableAPICall(() => getAdminATR(debouncedSearchTerm));

      if (isMounted()) {
        if (typeof resp === 'string') {
          setTableRejected();
          callback(resp);
        } else {
          setTableData({
            data: resp,
            totalResults: resp.length,
          });

          callback();
        }
      }
    },
    [debouncedSearchTerm, isMounted, setTableData, setTableRejected],
  );

  useEffect(() => {
    requestData((errorStatus) => {
      if (errorStatus) {
        setPageRejected(errorStatus);
      } else {
        setPageResolved();
      }
    });
  }, [requestData, setPageRejected, setPageResolved]);

  const columns = [
    {
      Header: 'ATR Name',
      id: 'actionName',
      resizable: false,
      sortable: false,
      filterable: false,
      Cell: ({ row: { original } }) => {
        const { name, accountId, readOnly } = original;

        if (checkPermissions(['admin:atr:create']) && utils.isEditable(accountId, activeAccountId, readOnly)) {
          return (
            <a
              href="#ATRDetails"
              onClick={(e) => {
                e.preventDefault();
                setATRObj(original);
                setIsEditing(true);
                setIsSliderOpen(true);
              }}
            >
              {name}
            </a>
          );
        }

        return name;
      },
    },
  ];

  if (totalAccounts > 1) {
    columns.push(
      {
        Header: 'EDITABLE',
        id: 'readOnly',
        width: 100,
        resizable: false,
        sortable: false,
        filterable: false,
        headerClassName: 'text-center',
        className: 'text-center',
        accessor: (r) => (r.readOnly ? 'No' : 'Yes'),
      },
      {
        Header: 'SHARED WITH',
        id: 'share',
        width: 200,
        resizable: false,
        sortable: false,
        filterable: false,
        Cell: ({ row: { original } }) => {
          let { sharedWith = [] } = original;

          sharedWith = sharedWith.filter(({ accountId }) => accountId !== activeAccountId);

          if (!sharedWith.length) return null;

          if (sharedWith.length === totalAccounts - 1) {
            return 'All';
          }

          const accountNames = sharedWith.map(({ accountName }) => accountName).join(', ');

          return <TippyTruncate>{accountNames}</TippyTruncate>;
        },
      },
    );
  }

  if (checkPermissions(['admin:atr:create'])) {
    columns.push({
      id: 'action',
      resizable: false,
      sortable: false,
      filterable: false,
      width: 63,
      className: 'action-cell',
      Cell: ({ row: { original } }) => {
        const { id, name, readOnly, accountId } = original;

        const editable = utils.isEditable(accountId, activeAccountId, readOnly);

        const menuItems = [
          {
            id: 0,
            label: 'Edit',
            icon: 'Pencil',
            permissions: ['admin:atr:create'],
            action: () => {
              setATRObj(original);
              setIsEditing(true);
              setIsSliderOpen(true);
              setActionMenuId('');
            },
          },
          {
            id: 1,
            label: 'Delete',
            icon: 'Bin',
            permissions: ['admin:atr:delete'],
            tooltipContent: 'Only the owner can delete this',
            action: () => {
              setDeleteObj({ id, name });
              setActionMenuId('');
              setShowConfirm(true);
            },
          },
        ];

        return (
          <ActionButton
            isDisabled={!editable}
            isOpen={actionMenuId === id}
            menuItems={menuItems}
            menuStyle={{ width: '150px' }}
            onToggle={(isOpen) => setActionMenuId(isOpen ? id : '')}
            position="left"
            subMenuProps={{
              menuStyle: { width: '100px' },
              position: 'left',
            }}
            title="Action"
            tooltipContent="Only the owner can edit this"
          />
        );
      },
    });
  }

  const cancelCallback = () => {
    setShowConfirm(false);
    setDeleteObj();
  };

  const confirmCallback = () => {
    deleteAction(
      deleteObj.id,
      () => {
        toast.success('ATR successfully deleted');

        const updatedTableData = deleteObjFromArray(tableState.data, deleteObj.id);

        setTableData({
          data: updatedTableData,
          totalResults: updatedTableData.length,
        });
      },
      () => {
        toast.error('Error deleting ATR');
      },
    );

    setShowConfirm(false);
  };

  useEffect(() => {
    setIsSliderOpen(isCreateMode);
    if (isCreateMode) setIsEditing(false);
  }, [isCreateMode]);

  function handleCloseSlider() {
    setIsSliderOpen(false);
    setATRObj({});
    if (isCreateMode) onClose();
  }

  if (!checkPermissions(['admin:atr:read'])) return null;

  return (
    <>
      <PageColumn
        className={cx('pt-1 overflow-visible', { 'h-scroll-table': tableState.data.length })}
        state={pageState}
      >
        <div className="d-flex justify-content-center w-100" style={{ marginLeft: '7px' }}>
          <EnhancedCard className="mt-3" style={{ maxWidth: '1600px', width: '100%' }}>
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h3 className="mb-0">Authorisation to Recruit</h3>
              <RestrictedCreateButton
                action={() => setIsSliderOpen(true)}
                floatRight={false}
                label="Create ATR"
                permissions={['admin:atr:create']}
              />
            </div>
            <div className="d-flex align-items-center gap-3 mb-4">
              <SearchInput
                value={searchTerm}
                className="w-100"
                type="text"
                placeholder="Search ATRs by Name"
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            {!tableState.data.length ? (
              <ClientAdminNoDataMessage
                btnProps={{
                  onClick: () => setIsSliderOpen(true),
                  label: searchTerm?.length > 0 ? null : 'Create ATR',
                }}
                message=""
                title={searchTerm?.length > 0 ? 'No ATRs match the filter' : 'You currently have no ATRs set up'}
              />
            ) : (
              <StyledTable
                className="mt-3"
                data={tableState.data}
                errorText="There has been an error loading ATRs, please try again later"
                hasSelectColumn={false}
                id="ATR"
                isLoading={tableState.isLoading}
                isRejected={tableState.isRejected}
                noDataText="You currently have no ATRs"
                pageSize={tableState.rowsPerPage}
                showPagination={false}
                columns={columns}
                totalResults={tableState.totalResults}
              />
            )}
          </EnhancedCard>
        </div>
      </PageColumn>
      <ClientAdminSlider
        closeSlider={handleCloseSlider}
        isSliderOpen={isSliderOpen}
        title={`${isEditing ? 'Edit' : 'Create'} ATR`}
      >
        <EditPanel
          data={isEditing ? ATRObj : {}}
          isEditing={isEditing}
          onCancel={handleCloseSlider}
          onSave={(wrkflwObj) => {
            handleCloseSlider();

            const updatedArr = isEditing
              ? updateObjInArray(tableState.data, wrkflwObj, wrkflwObj.id)
              : addObjToArray(tableState.data, wrkflwObj);

            setTableData({
              data: updatedArr,
              totalResults: updatedArr.length,
            });
          }}
        />
      </ClientAdminSlider>
      <Confirmation
        cancelCallback={cancelCallback}
        confirmCallback={confirmCallback}
        content={`Are you sure you want to delete ${(deleteObj || {}).name || ''} ATR?`}
        show={showConfirm}
      />
    </>
  );
}

AuthorisationToRecruit.propTypes = {
  isCreateMode: PropTypes.bool,
  onClose: PropTypes.func,
  totalAccounts: PropTypes.number,
  activeAccountId: PropTypes.string,
  tablePrefs: PropTypes.shape({
    pageSize: PropTypes.number,
  }),
};

AuthorisationToRecruit.defaultProps = {
  isCreateMode: false,
  totalAccounts: 0,
  activeAccountId: null,
  onClose: () => {},
  tablePrefs: {
    pageSize: 25,
  },
};

function mapStateToProps(state) {
  const {
    tablePrefs,
    userData: {
      userDetails: {
        data: { accountAccess = [] },
        activeAccountId,
      },
    },
  } = state;

  return {
    tablePrefs,
    totalAccounts: accountAccess.length,
    activeAccountId,
  };
}

export default connect(mapStateToProps)(AuthorisationToRecruit);
