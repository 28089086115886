import React, { useState, useEffect, Fragment } from 'react';
import { Spinner } from 'reactstrap';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { retryableAPICall } from '../../../../../api/common-api-utils';
import { listAllPermissions } from '../../../../../api/PermissionsAPI';
import { buildNestedPermissionObject, renderPermissionCheckboxes, renderSubPermissions } from './permissionUtils';
import { store } from '../../../../../js/store';

const Grid = styled.div`
  display: grid;
  grid-template-columns: 150px repeat(4, 1fr);
`;

const GridDivider = styled.div`
  background-color: white;
  grid-column-start: 1;
  grid-column-end: 6;
  height: ${({ height = '2em' }) => height};
`;

const TitleCell = styled.div`
  text-align: center;
`;
const TitleFeatureCell = styled.div`
  text-align: left;
  text-transform: capitalize;
`;

function PermissionsTable({ updateRoles, permissions }) {
  const [allPermissions, setAllPermissions] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const roles = store.getState().userData.userDetails.roles || [];

  useEffect(() => {
    const fetchPermissions = async () => {
      setIsLoading(true);
      try {
        const resp = await retryableAPICall(listAllPermissions);
        if (resp) setAllPermissions(buildNestedPermissionObject(resp));
      } catch (error) {
        toast.error('Failed to load permissions. Please try again later or contact support.');
      } finally {
        setIsLoading(false);
      }
    };
    fetchPermissions();
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="pm-grid-wrapper">
      <Grid className="pm-grid pm-grid-header">
        <GridDivider height="1em" />
        <TitleCell className="pm-grid-head-cell pm-grid-start-cell">Feature</TitleCell>
        <TitleCell className="pm-grid-head-cell">View</TitleCell>
        <TitleCell className="pm-grid-head-cell">Create</TitleCell>
        <TitleCell className="pm-grid-head-cell">Update</TitleCell>
        <TitleCell className="pm-grid-head-cell">Delete</TitleCell>
      </Grid>
      <Grid className="pm-grid pm-grid-inputs">
        {Object.entries(allPermissions)
          .sort(([a], [b]) => a.localeCompare(b))
          .map(([feature, featureObj], i) => {
            const { self, ...valuesWithoutSelf } = featureObj;

            return (
              <Fragment key={feature}>
                <GridDivider height={i === 0 ? '1em' : '2em'} />
                <TitleFeatureCell className={`${feature} pm-grid-cell pm-grid-start-cell pm-grid-top-cell`}>
                  <h6 className="mb-0">{feature}</h6>
                </TitleFeatureCell>
                {renderPermissionCheckboxes(featureObj, self, permissions, updateRoles, roles)}
                {renderSubPermissions(feature, valuesWithoutSelf, permissions, updateRoles, roles)}
              </Fragment>
            );
          })}
      </Grid>
    </div>
  );
}

export default PermissionsTable;
