import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import { toast } from 'react-toastify';
import Can from '../../../Base/RBAC/Can/Can';
import styled from 'styled-components';
import { listAllPermissionGroups, deletePermissionGroup } from '../../../../api/PermissionsAPI';
import { useMounted, usePageLoading } from '../../../Base/hooks';
import { useTableState, DataTable } from '../../../Base/Tables';
import { ClientAdminNoDataMessage, ClientAdminSlider, PageColumn } from '../Dashboard';
import PermissionsGroupEditor from './PermissionsGroupEditor';
import InfoAlert from './InfoAlert';
import { retryableAPICall } from '../../../../api/common-api-utils';
import { ActionButton, CreateButton } from '../../../Base/Buttons';
import { Confirmation } from '../../../Base/Modal';
import { deleteObjFromArray, updateObjInArray, addObjToArray } from '../../../../js/utils/arrayOfObjects';
import { checkPermissions } from '../../../../js/auth/AuthUtils';
import EnhancedCard from '../Common/EnhancedCard';

const RestrictedCreateButton = Can(CreateButton);

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid #e0e0e0;
  justify-content: space-between;
`;

const Title = styled.h4`
  color: #333;
  font-weight: 600;
  margin: 0;
  font-size: 1.25rem;
`;

async function deleteUser(id, onSuccess = () => {}, onError = () => {}) {
  if (id) {
    const resp = await retryableAPICall(() => deletePermissionGroup(id));

    if (typeof resp === 'string' && resp.length) {
      onError();
    } else {
      onSuccess();
    }
  }
}

function PermissionGroups({ isCreateMode, onClose, tablePrefs }) {
  const isMounted = useMounted();
  const { pageState, setPageResolved, setPageRejected } = usePageLoading();
  const { tableState, setTableData, setTableRejected } = useTableState({ rowsPerPage: tablePrefs.pageSize });
  const [showConfirm, setShowConfirm] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const [alertContent, setAlertContent] = useState();
  const [actionMenuId, setActionMenuId] = useState('');
  const [userObj, setUserObj] = useState({});
  const currentST = useRef(null);

  const requestData = useCallback(
    async (callback = () => {}) => {
      // eslint-disable-next-line max-len
      const resp = await retryableAPICall(() => listAllPermissionGroups());

      if (isMounted()) {
        if (typeof resp === 'string') {
          setTableRejected();
          callback(resp);
        } else {
          setTableData({
            data: resp,
            totalResults: resp.length,
          });
          callback();
        }
      }
    },
    [isMounted, setTableData, setTableRejected],
  );

  useEffect(() => {
    requestData((errorStatus) => {
      if (errorStatus) {
        setPageRejected(errorStatus);
      } else {
        setPageResolved();
      }
    });
  }, [requestData, setPageRejected, setPageResolved]);

  useEffect(() => {
    setIsSliderOpen(isCreateMode);
    if (isCreateMode) setIsEditing(false);
  }, [isCreateMode]);

  function handleCloseSlider() {
    setIsSliderOpen(false);
    if (isCreateMode) onClose();
  }

  const columns = [
    {
      Header: 'Name',
      id: 'name',
      minWidth: 200,
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original } }) => {
        // eslint-disable-next-line react/prop-types
        const { name } = original;
        return (
          <a
            href="#userDetails"
            onClick={(e) => {
              if (checkPermissions(['permissiongroup:write'])) {
                e.preventDefault();
                setUserObj(original);
                setIsEditing(true);
                setIsSliderOpen(true);
              }
            }}
          >
            {name}
          </a>
        );
      },
    },
  ];

  columns.push({
    id: 'action',
    width: 63,
    className: 'action-cell',
    // eslint-disable-next-line react/prop-types
    Cell: ({ row: { original } }) => {
      // eslint-disable-next-line react/prop-types
      const { id } = original;

      const menuItems = [
        {
          id: 0,
          label: 'Edit',
          icon: 'Pencil',
          permissions: ['permissiongroup:write'],
          action: () => {
            setUserObj(original);
            setIsEditing(true);
            setIsSliderOpen(true);
            setActionMenuId('');
          },
        },
        {
          id: 1,
          label: 'Delete',
          icon: 'Bin',
          permissions: ['permissiongroup:delete'],
          tooltipContent: 'You cannot delete yourself',
          action: () => {
            setDeleteUserId(id);
            setActionMenuId('');
            setShowConfirm(true);
          },
        },
      ];

      return (
        <ActionButton
          isOpen={actionMenuId === id}
          menuItems={menuItems}
          menuStyle={{ width: '150px' }}
          onToggle={(isOpen) => setActionMenuId(isOpen ? id : '')}
          position="left"
          subMenuProps={{
            menuStyle: { width: '100px' },
            position: 'left',
          }}
          title="Action"
        />
      );
    },
  });

  return (
    <>
      <PageColumn className={cx('pt-3', { 'h-scroll-table': tableState.data.length })} state={pageState}>
        <div style={{ maxWidth: '1600px', width: '100%', margin: '0 auto' }}>
          <EnhancedCard className="mb-4">
            <TitleWrapper>
              <Title>Permission Groups</Title>
              <RestrictedCreateButton
                action={() => {
                  setUserObj({});
                  setIsEditing(false);
                  setIsSliderOpen(true);
                }}
                floatRight={false}
                label="Create Permission Group"
                permissions={['permissiongroup:delete']}
              />
            </TitleWrapper>
            {!tableState.data.length && !currentST.current ? (
              <ClientAdminNoDataMessage
                btnProps={{
                  onClick: () => {
                    setUserObj({});
                    setIsEditing(false);
                    setIsSliderOpen(true);
                  },
                  label: 'Create permission group',
                }}
                message=""
                title="You currently have no Permission Groups set up"
              />
            ) : (
              <>
                <DataTable
                  className="mt-3"
                  columns={columns}
                  data={tableState.data}
                  errorText="There has been an error loading Permission Groups, please try again later"
                  hasSelectColumn={false}
                  id="user-admin"
                  isLoading={tableState.isLoading}
                  isRejected={tableState.isRejected}
                  noDataText={
                    currentST.current
                      ? 'No users match your search criteria'
                      : 'You currently have no Permission Groups'
                  }
                  pageSize={tableState.rowsPerPage}
                  rowHeight={53}
                  showPagination={false}
                  totalResults={tableState.totalResults}
                />
                <InfoAlert content={alertContent} onClose={() => setAlertContent()} />
              </>
            )}
          </EnhancedCard>
        </div>
      </PageColumn>
      <ClientAdminSlider
        closeSlider={handleCloseSlider}
        isSliderOpen={isSliderOpen}
        title={`${isEditing ? 'Edit' : 'Create'} Permission Group`}
      >
        <PermissionsGroupEditor
          data={isEditing ? userObj : {}}
          isEditing={isEditing}
          onCancel={handleCloseSlider}
          onSave={(updatedUserObj) => {
            handleCloseSlider();

            const updatedArr = isEditing
              ? updateObjInArray(tableState.data, updatedUserObj, updatedUserObj.id)
              : addObjToArray(tableState.data, updatedUserObj);

            setTableData({
              data: updatedArr,
              totalResults: updatedArr.length,
            });
          }}
        />
      </ClientAdminSlider>
      <Confirmation
        cancelCallback={() => setShowConfirm(false)}
        confirmCallback={() => {
          deleteUser(
            deleteUserId,
            () => {
              toast.success('Permission Group successfully deleted');

              const updatedArr = deleteObjFromArray(tableState.data, deleteUserId);

              setTableData({
                data: updatedArr,
                totalResults: updatedArr.length,
              });
            },
            () => {
              toast.error('Error deleting Permission Group');
            },
          );
          setShowConfirm(false);
        }}
        content="Are you sure you want to delete this Permission Group?"
        show={showConfirm}
      />
    </>
  );
}

PermissionGroups.propTypes = {
  isCreateMode: PropTypes.bool,
  onClose: PropTypes.func,
  tablePrefs: PropTypes.shape({
    pageSize: PropTypes.number,
  }),
  userId: PropTypes.string,
  tenant: PropTypes.string,
  searchTerm: PropTypes.string,
  roles: PropTypes.arrayOf(PropTypes.string),
  totalAccounts: PropTypes.number,
  loggedInEmail: PropTypes.string,
  activeAccountId: PropTypes.string,
  activeAccountName: PropTypes.string,
  setTitleSubItems: PropTypes.func,
};

PermissionGroups.defaultProps = {
  isCreateMode: false,
  onClose: () => {},
  tablePrefs: {
    pageSize: 150,
  },
};

function mapStateToProps(state) {
  const { tablePrefs } = state;

  return {
    tablePrefs,
  };
}

export default connect(mapStateToProps)(PermissionGroups);
