import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Col, Row, Badge } from 'reactstrap';
import Tabs from '../../../Base/Tabs/Tabs';
import { useMounted, usePageLoading } from '../../../Base/hooks';
import { useTableState, DataTable } from '../../../Base/Tables';
import { ClientAdminNoDataMessage, ClientAdminSlider, PageColumn } from '../Dashboard';
import UserAdminEditor from './UserAdminEditor';
import InfoAlert from './InfoAlert';
import { getUserListForAccount, deleteUser as deleteAccountUser } from '../../../../api/AccountAPI';
import { retryableAPICall } from '../../../../api/common-api-utils';
import { ActionButton } from '../../../Base/Buttons';
import { Confirmation } from '../../../Base/Modal';
import { deleteObjFromArray, updateObjInArray, addObjToArray } from '../../../../js/utils/arrayOfObjects';
import { checkPermissions } from '../../../../js/auth/AuthUtils';
import EnhancedCardTitle from '../Common/EnhancedCardTitle';
import EnhancedCard from '../Common/EnhancedCard';
import PermissionGroups from './PermissionGroups';

async function deleteUser(id, onSuccess = () => {}, onError = () => {}) {
  if (id) {
    const resp = await retryableAPICall(() => deleteAccountUser(id));

    if (typeof resp === 'string' && resp.length) {
      onError();
    } else {
      onSuccess();
    }
  }
}

function UserAdmin({
  isCreateMode,
  onClose,
  searchTerm,
  userId,
  tenant,
  tablePrefs,
  roles,
  totalAccounts,
  activeAccountId,
  activeAccountName,
  loggedInEmail,
  setTitleSubItems,
}) {
  const isMounted = useMounted();
  const [activeTab, setActiveTab] = useState('users');
  const { pageState, setPageResolved, setPageRejected } = usePageLoading();
  const { tableState, setTableData, setTableRejected } = useTableState({ rowsPerPage: tablePrefs.pageSize });
  const [showConfirm, setShowConfirm] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const [alertContent, setAlertContent] = useState();
  const [actionMenuId, setActionMenuId] = useState('');
  const [userObj, setUserObj] = useState({});
  const currentST = useRef(null);

  const requestData = useCallback(
    async (callback = () => {}) => {
      const resp = await retryableAPICall(() => getUserListForAccount(currentST.current));

      const pendingAtTop = resp
        .filter((user) => user.status === 'PENDING')
        .concat(resp.filter((user) => user.status !== 'PENDING'));

      if (isMounted()) {
        if (typeof resp === 'string') {
          setTableRejected();
          callback(resp);
        } else {
          setTableData({
            data: pendingAtTop,
            totalResults: resp.length,
          });

          const activeUsers = resp.filter((user) => user.status !== 'PENDING');
          setTitleSubItems([{ title: 'Active users', content: activeUsers?.length || 0 }]);

          callback();
        }
      }
    },
    [isMounted, setTableData, setTableRejected],
  );

  useEffect(() => {
    currentST.current = searchTerm;
    requestData((errorStatus) => {
      if (errorStatus) {
        setPageRejected(errorStatus);
      } else {
        setPageResolved();
      }
    });
  }, [requestData, setPageRejected, setPageResolved, searchTerm]);

  useEffect(() => {
    setIsSliderOpen(isCreateMode);
    if (isCreateMode) setIsEditing(false);
  }, [isCreateMode]);

  function handleCloseSlider() {
    setIsSliderOpen(false);
    if (isCreateMode) onClose();
  }

  const columns = [
    {
      Header: 'Name',
      id: 'name',
      minWidth: 200,
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original } }) => {
        // eslint-disable-next-line react/prop-types
        const { name, status, email } = original;

        if (checkPermissions(['admin:usermanagement:update'])) {
          if (!email.includes('support@e4s.co') || loggedInEmail.includes('support@e4s.co')) {
            return (
              <a
                href="#userDetails"
                onClick={(e) => {
                  e.preventDefault();
                  setUserObj(original);
                  setIsEditing(true);
                  setIsSliderOpen(true);
                }}
              >
                {name}
                {status === 'PENDING' && (
                  <Badge className="ms-2" color="warning" style={{ fontSize: '10px', padding: '0.3rem' }}>
                    Pending
                  </Badge>
                )}
              </a>
            );
          }
          <p>{name}</p>;
        }

        return name;
      },
    },
    {
      Header: 'Email Address',
      id: 'email',
      minWidth: 200,
      accessor: (r) => r.email,
    },
    {
      Header: 'Accounts',
      id: 'accounts',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original } }) => {
        // eslint-disable-next-line react/prop-types
        const { accounts } = original;
        // eslint-disable-next-line react/prop-types
        const accountNames = accounts.map(({ name }) => name).join(', ');

        return (
          <a
            href="#accountNames"
            onClick={(e) => {
              e.preventDefault();
              setAlertContent(accountNames);
            }}
          >
            {accountNames}
          </a>
        );
      },
    },
    {
      Header: 'Last Active',
      id: 'lastActive',
      width: 125,
      accessor: ({ lastActive }) => (lastActive ? moment(lastActive).format('DD-MM-YYYY') : ''),
    },
    {
      Header: 'Permission Group',
      id: 'permissionGroup',
      accessor: (data) => {
        const { permissionGroup } = data;

        return permissionGroup ? permissionGroup.name : 'Custom';
      },

      width: 125,
    },
  ];

  if (checkPermissions(['admin:usermanagement:update', 'admin:usermanagement:delete'])) {
    columns.push({
      id: 'action',
      width: 63,
      className: 'action-cell',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original } }) => {
        // eslint-disable-next-line react/prop-types
        const { id, email } = original;

        const noDelete = userId === id || email.includes('support@e4s.co');

        const menuItems = [
          {
            id: 0,
            label: 'Edit',
            icon: 'Pencil',
            isDisabled: !loggedInEmail.includes('support@e4s.co'),
            permissions: ['admin:usermanagement:update'],
            action: () => {
              setUserObj(original);
              setIsEditing(true);
              setIsSliderOpen(true);
              setActionMenuId('');
            },
          },
          {
            id: 1,
            label: 'Delete',
            icon: 'Bin',
            permissions: ['admin:usermanagement:delete'],
            isDisabled: noDelete,
            tooltipContent: 'You cannot delete yourself',
            action: () => {
              setDeleteUserId(id);
              setActionMenuId('');
              setShowConfirm(true);
            },
          },
        ];

        return (
          <ActionButton
            isOpen={actionMenuId === id}
            menuItems={menuItems}
            menuStyle={{ width: '150px' }}
            onToggle={(isOpen) => setActionMenuId(isOpen ? id : '')}
            position="left"
            subMenuProps={{
              menuStyle: { width: '100px' },
              position: 'left',
            }}
            title="Action"
          />
        );
      },
    });
  }

  return (
    <>
      <Col>
        {checkPermissions(['permissiongroup:read']) ? (
          <Row className="pb-3">
            <Col className="mx-auto">
              <div style={{ maxWidth: '1600px', width: '100%', margin: '0 auto' }}>
                <Row className="tab-row">
                  <Col className="ps-0">
                    <Tabs
                      activeTab={activeTab}
                      className="email-settings-tabs mb-1"
                      onClick={(id) => setActiveTab(id)}
                      positionAbsolute={false}
                      tabs={[
                        { anchor: 'users', label: 'Users' },
                        { anchor: 'permissions', label: 'Permission Groups' },
                      ]}
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        ) : null}
        <Row>
          {activeTab === 'users' ? (
            <>
              <PageColumn className={cx('pt-3', { 'h-scroll-table': tableState.data.length })} state={pageState}>
                <div style={{ maxWidth: '1600px', width: '100%', margin: '0 auto' }}>
                  <EnhancedCard className="mb-4">
                    <EnhancedCardTitle
                      title="User Management"
                      subtitle="Manage ATS users, update permissions or change account details"
                    />
                    {!tableState.data.length && !currentST.current ? (
                      <ClientAdminNoDataMessage
                        btnProps={{
                          onClick: () => setIsSliderOpen(true),
                          label: 'Create a User',
                        }}
                        message=""
                        title="You currently have no users set up"
                      />
                    ) : (
                      <>
                        <DataTable
                          className="mt-3"
                          columns={columns}
                          data={tableState.data}
                          errorText="There has been an error loading users, please try again later"
                          hasSelectColumn={false}
                          id="user-admin"
                          isLoading={tableState.isLoading}
                          // totalPages={tableState.totalPages}
                          isRejected={tableState.isRejected}
                          noDataText={
                            currentST.current ? 'No users match your search criteria' : 'You currently have no users'
                          }
                          pageSize={tableState.rowsPerPage}
                          rowHeight={53}
                          showPagination={false}
                          totalResults={tableState.totalResults}
                        />
                        <InfoAlert content={alertContent} onClose={() => setAlertContent()} />
                      </>
                    )}
                  </EnhancedCard>
                </div>
              </PageColumn>
              <ClientAdminSlider
                closeSlider={handleCloseSlider}
                isSliderOpen={isSliderOpen}
                title={`${isEditing ? 'Edit' : 'Create'} User`}
              >
                <UserAdminEditor
                  activeAccount={{
                    activeAccountName,
                    activeAccountId,
                  }}
                  data={isEditing ? userObj : {}}
                  isEditing={isEditing}
                  onCancel={handleCloseSlider}
                  onSave={(updatedUserObj) => {
                    handleCloseSlider();

                    const updatedArr = isEditing
                      ? updateObjInArray(tableState.data, updatedUserObj, updatedUserObj.id)
                      : addObjToArray(tableState.data, updatedUserObj);

                    setTableData({
                      data: updatedArr,
                      totalResults: updatedArr.length,
                    });
                  }}
                  roles={roles}
                  tenantId={tenant}
                  totalAccounts={totalAccounts}
                  userId={userId}
                />
              </ClientAdminSlider>
              <Confirmation
                cancelCallback={() => setShowConfirm(false)}
                confirmCallback={() => {
                  deleteUser(
                    deleteUserId,
                    () => {
                      toast.success('User successfully deleted');

                      const updatedArr = deleteObjFromArray(tableState.data, deleteUserId);

                      setTableData({
                        data: updatedArr,
                        totalResults: updatedArr.length,
                      });
                    },
                    () => {
                      toast.error('Error deleting user');
                    },
                  );
                  setShowConfirm(false);
                }}
                content="Are you sure you want to delete this user?"
                show={showConfirm}
              />
            </>
          ) : (
            <PermissionGroups />
          )}
        </Row>
      </Col>
    </>
  );
}

UserAdmin.propTypes = {
  isCreateMode: PropTypes.bool,
  onClose: PropTypes.func,
  tablePrefs: PropTypes.shape({
    pageSize: PropTypes.number,
  }),
  userId: PropTypes.string,
  tenant: PropTypes.string,
  searchTerm: PropTypes.string,
  roles: PropTypes.arrayOf(PropTypes.string),
  totalAccounts: PropTypes.number,
  loggedInEmail: PropTypes.string,
  activeAccountId: PropTypes.string,
  activeAccountName: PropTypes.string,
  setTitleSubItems: PropTypes.func,
};

UserAdmin.defaultProps = {
  isCreateMode: false,
  onClose: () => {},
  tablePrefs: {
    pageSize: 150,
  },
  userId: null,
  tenant: null,
  searchTerm: null,
  loggedInEmail: '',
  roles: [],
  totalAccounts: 0,
  activeAccountId: undefined,
  activeAccountName: undefined,
  setTitleSubItems: () => {},
};

function mapStateToProps(state) {
  const {
    tablePrefs,
    userData: {
      userDetails: {
        id,
        data: { tenant, accountAccess = [] },
        email,
        roles,
        activeAccountId,
        activeAccountName,
      },
    },
  } = state;

  return {
    tablePrefs,
    loggedInEmail: email,
    userId: id,
    tenant,
    roles,
    totalAccounts: accountAccess.length,
    activeAccountId,
    activeAccountName,
  };
}

export default connect(mapStateToProps)(UserAdmin);
