import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import WorkflowSelect from './WorkflowSelect';
import { useMounted } from '../../../../../../Base/hooks';
import { retryableAPICall } from '../../../../../../../api/common-api-utils';
import { getEventType } from '../../../../../../../api/SchedulingAPI/EventTypeAPI';

async function getEventTypes(onSuccess = () => {}) {
  const resp = await retryableAPICall(() => getEventType());

  if (typeof resp === 'string') {
    toast.error('Error loading Event Types, please try again later');
  } else {
    const opts = resp.map(({ id, name }) => ({ value: id, label: name }));
    onSuccess(opts);
  }
}

function EventTypeSelect({ id, value, error, onChange }) {
  const isMounted = useMounted();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    getEventTypes((resp) => {
      if (isMounted()) {
        setOptions(resp);
      }
    });
  }, [isMounted]);

  return (
    <WorkflowSelect
      id={id}
      label="Choose Event Type"
      required
      placeholder="Choose Event Type"
      options={options}
      value={value}
      error={error}
      onChange={onChange}
      isMulti
    />
  );
}

EventTypeSelect.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.string,
};

EventTypeSelect.defaultProps = {
  id: null,
  value: null,
  onChange: () => {},
  error: null,
};

export default EventTypeSelect;
