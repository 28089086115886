export const payrollStates = [
  { label: 'Failed to submit', value: 'FAILED' },
  { label: 'Submitted Successfully', value: 'SUCCESS' },
];

export const defaultStates = [
  { label: 'Received', value: 'RECEIVED' },
  { label: 'Requested', value: 'REQUESTED' },
];

export const referenceStates = [
  { label: 'Received', value: 'RECEIVED' },
  { label: 'Part Recieved', value: 'PARTRECEIVED' },
  { label: 'Requested', value: 'REQUESTED' },
];

export const rtwStates = [
  { label: 'Received', value: 'RECEIVED' },
  { label: 'Requested', value: 'REQUESTED' },
];
export const rtwOutcomes = [
  { value: 'ANY', label: 'Any' },
  { value: 'REJECTED', label: 'Rejected' },
  { value: 'TIME_LIMITED', label: 'Time Limited' },
  { value: 'CONTINUOUS', label: 'Continuous' },
];

export const personalityTestState = [
  { label: 'Received', value: 'RECEIVED' },
  { label: 'Requested', value: 'REQUESTED' },
];

export const personalityTestScoreValues = [
  { value: 'Any', label: 'Any' },
  { value: 'LT', label: 'Less Than' },
  { value: 'EQ', label: 'Equal to' },
  { value: 'GT', label: 'Greater Than' },
];

export const personalityTestScoreTypes = [
  { value: 'INTEGRITYSCORE', label: 'Integrity Score' },
  { value: 'OTHER', label: 'Other' },
];

export const eventStates = [
  { label: 'Invited', value: 'RECEIVED' },
  { label: 'Accepted', value: 'REQUESTED' },
  { label: 'Declined', value: 'DECLINED' },
  { label: 'Attended', value: 'ATTENDED' },
  { label: 'No Show', value: 'NOSHOW' },
];

export const newCandidateFilterTypes = [
  { label: 'All', value: 'ALL' },
  { label: 'UTM Source', value: 'UTMSOURCE' },
  { label: 'UTM Campaign', value: 'UTMCAMPAIGN' },
  { label: 'UTM Medium', value: 'UTMMEDIUM' },
];

export default {
  payrollStates,
  defaultStates,
  referenceStates,
  rtwStates,
  rtwOutcomes,
  personalityTestState,
  personalityTestScoreValues,
  personalityTestScoreTypes,
  eventStates,
  newCandidateFilterTypes,
};
