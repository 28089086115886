import React from 'react';
import styled from 'styled-components';

const CbCell = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

function CheckboxCell({ id, className, checked, onChange, isDisabled }) {
  return (
    <CbCell className={className || ''}>
      <input
        id={id}
        checked={checked}
        type="checkbox"
        onChange={(e) => onChange(e.target.checked)}
        disabled={isDisabled}
      />
    </CbCell>
  );
}

export default CheckboxCell;
