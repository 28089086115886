import React from 'react';
import { FormGroup, Label } from 'reactstrap';
import Select from 'react-select';
import Decision from './index';
import { CreateButton } from '../../../../../../../Base/Buttons';
import styled from 'styled-components';

const optionsList = [
  { label: 'DBS Status', value: 'dbsStatus' },
  { label: 'E-document Status', value: 'edocStatus' },
  { label: 'Event Status', value: 'eventStatus' },
  { label: 'FlexForm Status', value: 'flexFormStatus' },
  { label: 'Funnel Stage', value: 'funnelStage' },
  { label: 'New Candidate', value: 'newCandidate' },
  { label: 'Onboarding Status', value: 'onboardingStatus' },
  { label: 'Payroll Submission', value: 'payrollSubmission' },
  { label: 'Personality Test Status', value: 'personalityTestStatus' },
  { label: 'Referee Status', value: 'refereeStatus' },
  { label: 'Reference Status', value: 'referenceStatus' },
  { label: 'RTW Status', value: 'rtwStatus' },
];

const OperatorList = [
  { label: 'All', value: 'AND' },
  { label: 'Any', value: 'OR' },
];

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  width: 35rem;
`;
const ConditionalBox = ({ selectedNode, onChange }) => {
  const nodes = selectedNode.decision || { conditions: [], operator: 'OR' };

  return (
    <div>
      <h5>IF</h5>
      <StyledContainer>
        <Select
          options={OperatorList}
          value={OperatorList.find((a) => a.value === nodes.operator)}
          onChange={(a) => {
            onChange({ decision: { ...nodes, operator: a.value } });
          }}
          className="me-3"
        />
        <div>of these conditions are met</div>
      </StyledContainer>
      {nodes?.conditions?.map((group) => (
        <div
          style={{
            marginLeft: '10px',
            border: '1px solid lightGray',
            borderRadius: '5x',
            padding: '10px',
            marginBottom: '10px',
            marginTop: '10px',
          }}
        >
          <FormGroup>
            <Label>Condition:</Label>
            <Select
              options={optionsList}
              value={optionsList.find((a) => a.value === group.value)}
              onChange={(updateValue) => {
                const newNodes = nodes.conditions.reduce((acc, curr, index) => {
                  if (curr.id === group.id) {
                    acc.push({ ...curr, value: updateValue.value });
                  } else {
                    acc.push(curr);
                  }
                  return acc;
                }, []);

                onChange({ decision: { ...nodes, conditions: newNodes } });
              }}
            />
          </FormGroup>
          <Decision
            option={group}
            setOption={(updateValue) => {
              const newNodes = nodes.conditions.reduce((acc, curr, index) => {
                if (curr.id === group.id) {
                  acc.push({ ...curr, ...updateValue });
                } else {
                  acc.push(curr);
                }
                return acc;
              }, []);

              onChange({ decision: { ...nodes, conditions: newNodes } });
            }}
          />
        </div>
      ))}
      <CreateButton
        className="my-4"
        label="Add Condition"
        action={() =>
          onChange({
            decision: {
              ...nodes,
              conditions: [
                ...nodes.conditions,
                { value: 'flexFormStatus', condition: 'received', id: new Date().getTime() },
              ],
            },
          })
        }
      ></CreateButton>
    </div>
  );
};

export default ConditionalBox;
