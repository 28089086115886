import PropTypes from 'prop-types';
import React from 'react';
import { getSubmission, submitToTamigo } from '../../../../api/Integrations/TamigoAPI';
import { retryableAPICall } from '../../../../api/common-api-utils';
import Integration from './Integration';

const TamigoInputConfig = [
  {
    id: 'department',
    label: 'Department',
    type: 'select',
    required: true,
    url: 'integration/data/tamigo/departments',
    dataMapper: (data) => {
      return { label: data.label, value: data.value };
    },
  },
  {
    id: 'position',
    label: 'Position',
    type: 'select',
    required: true,
    url: 'integration/data/tamigo/positions',
    dataMapper: (data) => {
      return { label: data.label, value: data.value };
    },
  },
  {
    id: 'startDate',
    label: 'Start Date',
    required: true,
    type: 'date',
  },
  {
    id: 'wageModel',
    label: 'Wage Model',
    type: 'select',
    required: true,
    url: 'integration/data/tamigo/contractTypes',
    dataMapper: (data) => {
      return { label: data.label, value: data.value };
    },
  },
  {
    id: 'hourlyRate',
    label: 'Hourly Rate',
    type: 'number',
    defaultValue: 0.0,
    inputProps: {
      min: 0,
    },
  },
  {
    id: 'monthlySalary',
    label: 'Monthly Salary',
    type: 'number',
    defaultValue: 0.0,
    inputProps: {
      min: 0,
    },
  },
  {
    id: 'salary',
    label: 'Salary',
    type: 'number',
    defaultValue: 0.0,
    inputProps: {
      min: 0,
    },
  },
  {
    id: 'contractHours',
    label: 'Contract Hours',
    type: 'number',
    defaultValue: 0.0,
    inputProps: {
      min: 0,
    },
  },
];

function valueSetter(url, value, isDisabled, isComplete, type, formData, id) {
  let convertedValue = type === 'number' && !formData[id] ? 0 : type === 'checkbox' ? formData[id] === 1 : value;

  if (isComplete) {
    convertedValue = formData[id];
  }

  return { value: convertedValue, url, isDisabled, isHidden: false };
}

function TamigoForm({ applicantId }) {
  const formSubmit = async (applicantId, formData) => {
    try {
      const { status, message, submissionRequest } = await retryableAPICall(() =>
        submitToTamigo(applicantId, {
          dataFields: {
            contractHours: formData.contractHours,
            department: formData.department,
            startDate: formData.startDate,
            wageModel: formData.wageModel,
            monthlySalary: formData.monthlySalary,
            position: formData.position,
            salary: formData.salary,
            hourlyRate: formData.hourlyRate,
          },
        }),
      );
      return status === 'NOT_FOUND_ERROR'
        ? 'NOT_FOUND_ERROR'
        : { status: status === 'PENDING' ? 'FAILED' : status, message, data: submissionRequest?.dataFields };
    } catch (error) {
      console.error('Error during form submission:', error);
      return { status: 'ERROR', message: 'An unexpected error occurred during data fetching.' };
    }
  };

  const handleChange = (id, value, formData, setFormData) => {
    const updatedData = { ...formData, [id]: value };

    setFormData(updatedData);
  };

  async function getTamigoData(applicantId) {
    try {
      const resp = await retryableAPICall(() => getSubmission(applicantId));

      if (resp === '') {
        return 'NOT_FOUND_ERROR';
      }

      const { status, message, submissionRequest } = resp;

      const newData = {
        ...submissionRequest?.dataFields,
        departmentName: submissionRequest?.dataFields?.department?.name,
      };

      if (status !== 'SUCCESS') {
        return {
          status: status === 'PENDING' ? 'FAILED' : status,
          message: message,
          data: newData,
        };
      }

      return {
        status: 'SUCCESS',
        data: newData,
      };
    } catch (error) {
      console.error('Error fetching data:', error);
      return {
        status: 'ERROR',
        message: 'An unexpected error occurred during data fetching.',
      };
    }
  }

  const validationConfig = () => {
    return TamigoInputConfig.reduce((acc, { id, type, required, inputProps }) => {
      const { min, max } = inputProps || {};
      return [...acc, { id, required, type, ...(type === 'number' ? { type, min, max } : {}) }];
    }, []);
  };

  return (
    <Integration
      title="Tamigo"
      applicantId={applicantId}
      valueSetter={valueSetter}
      integrationName="Tamigo"
      formConfig={TamigoInputConfig}
      formSubmit={formSubmit}
      getSubmission={getTamigoData}
      componentName="Tamigo"
      customOnChange={handleChange}
      getValidationConfig={validationConfig}
    />
  );
}

TamigoForm.propTypes = {
  applicantId: PropTypes.string,
};

TamigoForm.defaultProps = {
  applicantId: null,
};

export default TamigoForm;
