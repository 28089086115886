import { filterUtils } from '../../views/Base/Filters';
import {
  ADD_ACTIVE_FILTER,
  DELETE_ACTIVE_FILTER,
  RESET_FILTER_STATE,
  UPDATE_ACTIVE_FILTER,
  BULK_ADD_FILTERS,
} from '../constants/actionTypes';

const initialState = {
  activeFilters: {
    vacancyList: [
      {
        id: 0,
        field: 'status',
        operation: 'EQ',
        value: ['ACTIVE', 'PENDING', 'AUTHORISATION_REJECTED', 'AWAITING_AUTHORISATION', 'PAUSED'],
        instanceId: '0',
        filterName: 'vacancyList',
        type: 'Checkbox',
        checked: false,
      },
    ],
  },
};

//
const filterReducer = (state = initialState, action) => {
  const { type, payload } = action;
  const { activeFilters } = state;

  const activeFilterCp = { ...activeFilters };

  switch (type) {
    case ADD_ACTIVE_FILTER:
      if (!activeFilterCp[payload.filterName]) {
        activeFilterCp[payload.filterName] = [];
      }

      // payload.isNew = true;
      if (!filterUtils.isDupeFilter(activeFilterCp[payload.filterName], payload)) {
        activeFilterCp[payload.filterName].push(JSON.parse(JSON.stringify(payload)));
      }

      return {
        ...state,
        activeFilters: activeFilterCp,
      };
    case BULK_ADD_FILTERS:
      return {
        ...state,
        activeFilters: {
          ...state.activeFilters,
          ...payload,
        },
      };
    case UPDATE_ACTIVE_FILTER:
      activeFilterCp[payload.filterName] = activeFilterCp[payload.filterName].filter(
        (filter) => filter.instanceId !== payload.instanceId,
      );
      activeFilterCp[payload.filterName].push(JSON.parse(JSON.stringify(payload)));
      return {
        ...state,
        activeFilters: {
          ...state.activeFilters,
          [payload.filterName]: [...activeFilterCp[payload.filterName]],
        },
      };
    case DELETE_ACTIVE_FILTER:
      return {
        ...state,
        activeFilters: {
          ...activeFilters,
          [payload.filterName]: (activeFilterCp[payload.filterName] || []).filter(
            (filter) => filter.instanceId !== payload.instanceId,
          ),
        },
      };
    case RESET_FILTER_STATE:
      return { ...initialState };
    default:
      return state;
  }
};

export default filterReducer;
