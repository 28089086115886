// export const PRIMARY_COLOUR = '#283540';
export const DARK_GREY = '#C6CBCF';
export const PRIMARY_COLOUR = '#5A5A5A';
export const SECONDARY_COLOUR = '#1A2E56';
export const HEADER_PRIMARY_COLOUR = SECONDARY_COLOUR;
export const HEADER_SECONDARY_COLOUR = DARK_GREY;
export const ACTION_COLOUR = '#5285EF';
export const DANGER = '#DE5471';
export const PURPLE = '#6F08B8';
export const YELLOW = '#E8C100';
export const HOVER_GREY = '#F0F1F5';
export const ORANGE = '#EC9232';
export const GREEN = '#4ace76';

export const CALENDAR = {
  EVENT_BG: '#3788D8',
  EXTERNAL_EVENT_BG: '#81a669',
  EVENT_PARTIALLY_BOOKED_BG: ORANGE,
  EVENT_FULLY_BOOKED_BG: GREEN,
};
