import React, { useState } from 'react';
import { actionNodes, waitNodes, exitNodes, inputNodes } from '../NodeTypesLists';
import NodeAccordion from './NodeAccordion'; // Import the new component
import styled from 'styled-components';

const StyledWrapper = styled.div`
  position: absolute;
  z-index: 2;
  top: 2rem;
  left: 2rem;
  background: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 1rem;
`;

const NodeSelector = ({ nodes }) => {
  const hasInputNode = nodes.some((node) => node.type === 'startNode');
  const inputNodeList = nodes.filter((node) => node.type === 'startNode');
  const allowedActions =
    inputNodeList && inputNodeList.length > 0
      ? inputNodes.find((a) => {
          return a.actionType === inputNodeList[0].data.actionType;
        }).actions
      : [];

  return (
    <StyledWrapper>
      {!hasInputNode ? (
        <NodeAccordion
          header="Triggers"
          nodes={inputNodes}
          isDisabled={hasInputNode}
          warning={hasInputNode ? 'Only one input node allowed' : null}
        />
      ) : (
        <>
          <NodeAccordion
            header="Actions"
            nodes={actionNodes.filter((a) => {
              return allowedActions.includes(a.actionType);
            })}
            warning={!hasInputNode ? 'Select trigger to see actions' : null}
            isDisabled={!hasInputNode}
          />
          <NodeAccordion header="Delays" nodes={waitNodes} />
          <NodeAccordion header="Flow Control" nodes={exitNodes} />
          {/* <NodeAccordion header="Decision" nodes={decisionNodes} isDisabled={!hasInputNode} /> */}
          {/* <NodeAccordion header="Notes" nodes={notesNodes} />*/}
        </>
      )}
    </StyledWrapper>
  );
};

export default NodeSelector;
