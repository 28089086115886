import React, { memo, useState } from 'react';
import { Handle, Position } from '@xyflow/react';
import styled from 'styled-components';
import ActionButtons from './ActionButtons';
import { SliderContext } from './SliderContext';
import { exitNodes } from '../NodeTypesLists';
import { useHover } from '../../HoverContext';

const Container = styled.div`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  border: ${({ isHighlighted }) => (isHighlighted ? '2px dashed red' : 'none')};
`;

const Icon = styled.div`
  background-color: darkred;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  display: flex;
  padding: 0.25rem;

  svg {
    margin: auto;
    color: white;
    width: 16px;
    height: 16px;
  }
`;

const Text = styled.div`
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
  text-align: left;
  font-size: 8px;
  display: flex;
  flex-direction: column;
  color: #1a2e56;

  .title {
    font-size: 12px;
    font-weight: bold;
  }

  .status {
    text-transform: capitalize;
  }
`;

export default memo(({ id, data, isConnectable }) => {
  const nodeType = exitNodes.find((node) => node.actionType === data?.actionType);
  const IconComp = nodeType?.icon;
  const { hoveredId } = useHover();

  const [isSliderOpen, setIsSliderOpen] = useState(false);

  const handleDoubleClick = () => {
    setIsSliderOpen(true);
  };

  return (
    <SliderContext.Provider value={{ isSliderOpen, setIsSliderOpen }}>
      <div onDoubleClick={handleDoubleClick}>
        <ActionButtons id={id} data={data} />
        <Container isHighlighted={id === hoveredId}>
          <Handle type="target" position={Position.Top} style={{ background: '#555' }} isConnectable={isConnectable} />
          <Icon>
            <IconComp />
          </Icon>
          <Text>
            <div className="title">Exit Workflow</div>
          </Text>
        </Container>
      </div>
    </SliderContext.Provider>
  );
});
