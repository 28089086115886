import React from 'react';
import styled from 'styled-components';
import Select, { components } from 'react-select';

const OPTION_HEIGHT = 60; // Approximate height of each option
const MAX_VISIBLE_OPTIONS = 3;

const UserCircle = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  margin-right: ${(props) => (props.isOption ? '8px' : '2px')};
  font-size: 12px;
  color: white;
  font-weight: bold;
`;

const MultiValueContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 2px;
  margin: 2px;
  padding: 2px;
`;

const MultiValueLabel = styled.div`
  padding: 0 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const UserOption = styled.div`
  display: flex;
  align-items: center;
`;

const SelectWrapper = styled.div`
  margin-top: 0px;
`;

const RemoveButton = styled.div`
  color: #999;
  padding: 0 5px;
  cursor: pointer;
  &:hover {
    color: #666;
    background-color: #e0e0e0;
  }
`;

const UserSelector = ({ users, onChange, error, defaultValue }) => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: '40px',
      height: 'auto',
      maxHeight: state.selectProps.menuIsOpen ? '200px' : 'none',
      overflow: 'auto',
      borderColor: error ? 'rgba(255, 0, 0, 0.5)' : provided.borderColor,
      '&:hover': {
        borderColor: error ? 'rgba(255, 0, 0, 0.8)' : provided['&:hover'].borderColor,
      },
      boxShadow: error ? '0 0 0 1px rgba(255, 0, 0, 0.1)' : provided.boxShadow,
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '2px 8px',
      flexWrap: 'wrap',
      overflow: 'auto',
      maxHeight: '196px',
    }),
    input: (provided) => ({
      ...provided,
      margin: '0px',
    }),
    option: (provided) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
    }),
    menu: (provided) => ({
      ...provided,
      maxHeight: `${OPTION_HEIGHT * MAX_VISIBLE_OPTIONS}px`,
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: `${OPTION_HEIGHT * MAX_VISIBLE_OPTIONS}px`,
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: 'transparent',
      margin: '2px 4px 2px 0',
    }),
    multiValueRemove: () => ({}),
  };

  const CustomMultiValue = ({ children, removeProps, ...props }) => (
    <MultiValueContainer>
      <UserCircle color={props.data.color} isOption={false}>
        {props.data.initials}
      </UserCircle>
      <MultiValueLabel>{props.data.label}</MultiValueLabel>
      <RemoveButton {...removeProps}>×</RemoveButton>
    </MultiValueContainer>
  );

  const formatOptionLabel = ({ label, email, color, initials }) => (
    <UserOption>
      <UserCircle color={color} isOption={true}>
        {initials}
      </UserCircle>
      <div>
        <div>{label}</div>
        <div style={{ fontSize: '12px', color: '#666' }}>{email}</div>
      </div>
    </UserOption>
  );

  return (
    <SelectWrapper>
      <Select
        isMulti
        options={users}
        styles={customStyles}
        formatOptionLabel={formatOptionLabel}
        onChange={onChange}
        placeholder="Select users to share with..."
        isSearchable={true}
        maxMenuHeight={200}
        defaultValue={defaultValue}
        components={{
          MultiValue: CustomMultiValue,
        }}
      />
    </SelectWrapper>
  );
};

export default UserSelector;
