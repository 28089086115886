import React, { useEffect, useState } from 'react';
import { syncCalendarCallback } from '../../api/SchedulingAPI/CalendarAPI';

function SyncCallBackView() {
  const [statusMessage, setStatusMessage] = useState(undefined);
  const [errorOccurred, setErrorOccurred] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    let type;

    if (urlParams.has("scope") && urlParams.get("scope").indexOf("google") !== -1) {
      type = "Google";
    } else if (!urlParams.has("scope")) {
      type = "Outlook";
    }
    setStatusMessage(`Syncing to ${type} Calendar...`)
    if (!code) {
      setStatusMessage('Authorization code not found. Please try again.');
      setErrorOccurred(true);
      return;
    }

    (async () => {
      try {
        const syncData = JSON.parse(localStorage.getItem('calendarSyncData'));
        if (syncData) {
          const { selectedAccounts, selectedColour, syncType, obfuscatedSync, onlyPersonal } = syncData;
          debugger
          const response = await syncCalendarCallback(code, selectedAccounts?.map(account => account.value), selectedColour?.value, type, syncType, obfuscatedSync, onlyPersonal);
          if (response) {
            setStatusMessage(`Successfully synced with ${type} Calendar!`);
          } else {
            throw new Error('Failed to sync. The API response was null.');
          }
          localStorage.removeItem('calendarSyncData');
        } else {
          throw new Error('Sync data not found in local storage.');
        }
      } catch (error) {
        console.error('Error while exchanging code:', error);
        setStatusMessage(`Failed to sync with ${type} Calendar. Error: ${error.message}`);
        setErrorOccurred(true);
      }

      // Add class to hide .app-header
      const appHeader = document.querySelector('.app-header');
      const appFooter = document.querySelector('.app-footer');
      if (appHeader) {
        appHeader.classList.add('hidden');
        appFooter.classList.add('hidden');
      }

      // Cleanup function to remove the class
      return () => {
        if (appHeader) {
          appHeader.classList.remove('hidden');
          appFooter.classList.remove('hidden');
        }
      };
    })();
  }, []);

  const handleClose = () => {
    window.close();
  };

  const statusStyle = {
    padding: '20px',
    backgroundColor: errorOccurred ? '#ffcccc' : '#ccffcc',
    color: errorOccurred ? '#cc0000' : '#006600',
    border: `2px solid ${errorOccurred ? '#cc0000' : '#006600'}`,
    borderRadius: '5px',
    marginTop: '20px',
    textAlign: 'center',
    fontSize: '18px',
    wordWrap: 'break-word', // Ensure the text wraps within the container
  };

  const buttonStyle = {
    display: 'block',
    marginTop: '20px',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '10px 20px',
    fontSize: '18px',
    cursor: 'pointer',
    backgroundColor: '#006600', // Button background color
    color: '#ffffff', // Button text color
    border: 'none', // Remove default border
    borderRadius: '5px', // Rounded corners
  };

  return (
    <div style={{ width: '80%', margin: '0 auto', textAlign: 'center' }}>
      <p style={statusStyle}>{statusMessage}</p>
      <button onClick={handleClose} style={buttonStyle}>Close</button>
    </div>
  );
}

export default SyncCallBackView;
