import React, { memo, useState } from 'react';
import { Handle, Position } from '@xyflow/react';
import styled from 'styled-components';
import ActionButtons from './ActionButtons';
import { SliderContext } from './SliderContext';
import { inputNodes } from '../NodeTypesLists';
import { useHover } from '../../HoverContext';

const Container = styled.div`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  border: ${({ isHighlighted }) => (isHighlighted ? '2px dashed red' : 'none')};
`;

const Icon = styled.div`
  background-color: green;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  display: flex;
  padding: 0.25rem;

  svg {
    margin: auto;
    color: white;
    width: 16px;
    height: 16px;
  }
`;

const Text = styled.div`
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
  text-align: left;
  font-size: 8px;
  display: flex;
  flex-direction: column;
  color: #1a2e56;

  .title {
    font-size: 12px;
    font-weight: bold;
  }

  .status {
    text-transform: capitalize;
  }

  .error {
    color: red;
  }
`;

const eventStatusMap = {
  INVITED: 'Invited',
  ACCEPTED: 'Accepted',
  DECLINED: 'Declined',
  ATTENDED: 'Attended',
  NOSHOW: 'No Show',
};

function hasData(data) {
  let message = '';

  if (data.actionType === 'FUNNEL_STATE_CHANGE') {
    message = data.funnelId && data.stageId ? 'Funnel Selected' : null;
  } else if (data.actionType === 'CREATE_CANDIDATE_EVENT') {
    message = data.condition?.value;
    if (data.candidateFilterVal) {
      message = `${message} is ${data.candidateFilterVal}`;
    }
  } else if (data.actionType === 'EVENT_STATUS_CHANGE') {
    message = eventStatusMap[data.condition];
  } else {
    message = data.condition;
  }
  return message;
}

export default memo(({ id, data }) => {
  const { hoveredId } = useHover();
  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const nodeType = inputNodes.find((node) => node.actionType === data?.actionType);
  const label = nodeType?.label;
  const IconComp = nodeType?.icon;

  const handleDoubleClick = () => {
    setIsSliderOpen(true);
  };

  return (
    <SliderContext.Provider value={{ isSliderOpen, setIsSliderOpen }}>
      <div onDoubleClick={handleDoubleClick}>
        <ActionButtons id={id} data={data} disableCopy />
        <Container isHighlighted={id === hoveredId}>
          <Icon>
            <IconComp />
          </Icon>
          <Text>
            <div className="title">{label}</div>
            {hasData(data) ? (
              <div className="status">{hasData(data)}</div>
            ) : (
              <div className="error">Edit to choose status</div>
            )}
          </Text>
        </Container>
        <Handle type="source" position={Position.Bottom} />
      </div>
    </SliderContext.Provider>
  );
});
