import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label } from 'reactstrap';
import { Spinner } from 'reactstrap';
import { toast } from 'react-toastify';
import { retryableAPICall } from '../../../../../api/common-api-utils';
import { listAllPermissionGroups } from '../../../../../api/PermissionsAPI';
import PermissionGroupDropdown from './PermissionGroupDropdown';
import PermissionsTable from './PermissionsTable';
import { Confirmation } from '../../../Modal';

function PermissionsMatrix(props) {
  const {
    schema: { title },
    idSchema: { $id: id },
    formData,
    onChange,
  } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [allPermissionGroups, setAllPermissionGroups] = useState([]);
  const [allPermissions, setAllPermissions] = useState(formData.permissions);
  const [showCustomWarning, setShowCustomWarning] = useState(false);
  const [cachedCustomPermissions, setCachedCustomPermissions] = useState([]);

  useEffect(() => {
    const fetchPermissions = async () => {
      setIsLoading(true);
      try {
        const resp = await retryableAPICall(listAllPermissionGroups);
        if (resp) setAllPermissionGroups(resp);
      } catch (error) {
        toast.error('Failed to load permissions. Please try again later or contact support.');
      } finally {
        setIsLoading(false);
      }
    };

    if (allPermissionGroups.length === 0) {
      fetchPermissions();
    }
  }, [formData.permissionGroup]);

  useEffect(() => {
    const setGroups = async () => {
      if (formData.permissionGroup && allPermissionGroups.length !== 0) {
        const perms = allPermissionGroups.find((group) => group.id === formData.permissionGroup.id);
        setAllPermissions(perms.permissions);
      } else {
        setAllPermissions(formData.permissions);
      }
    };
    setGroups();
  }, [formData.permissionGroup, allPermissionGroups]);

  useEffect(() => {
    if (!formData.permissionGroup) {
      setAllPermissions(formData.permissions);
    }
  }, [formData.permissions]);

  if (isLoading) {
    return <Spinner />;
  }

  const selectedGroup = formData?.permissionGroup
    ? {
        value: formData.permissionGroup.id,
        label: formData.permissionGroup.name,
      }
    : { value: '', label: 'Custom Permissions' };

  return (
    <FormGroup>
      <Label for={id}>{title}</Label>
      <PermissionGroupDropdown
        selectedGroup={selectedGroup}
        setSelectedGroup={(a) => {
          onChange({
            permissionGroup: a.value ? { id: a.value, name: a.label } : '',
            permissions: [],
          });
        }}
      />
      <PermissionsTable
        permissions={allPermissions}
        isDisabled={formData?.permissionGroup}
        updateRoles={(role, checked) => {
          if (formData.permissionGroup) {
            setShowCustomWarning(true);
            const perms = checked
              ? [...allPermissions, role]
              : allPermissions.filter((permissions) => permissions !== role);

            setCachedCustomPermissions(perms);
          } else {
            const updatedAuthorisers = checked
              ? [...allPermissions, role]
              : allPermissions.filter((permissions) => permissions !== role);

            onChange({
              permissionGroup: '',
              permissions: updatedAuthorisers,
            });
          }
        }}
      />
      <Confirmation
        cancelCallback={() => {
          setCachedCustomPermissions([]);
          setShowCustomWarning(false);
        }}
        confirmCallback={() => {
          onChange({
            permissionGroup: '',
            permissions: cachedCustomPermissions,
          });
          setCachedCustomPermissions([]);
          setShowCustomWarning(false);
        }}
        content={
          <p>
            <strong style={{ color: 'red', fontSize: '20px' }}>Warning!</strong>
            <br />
            <br /> Changing this will move you out of the permission group and into a custom list and won't be updated
            when the permission group changes
            <br />
            <br />
            You should be <strong>absolutely sure</strong> you want to perform this action before confirming.
          </p>
        }
        show={showCustomWarning}
      />
    </FormGroup>
  );
}

PermissionsMatrix.propTypes = {
  schema: PropTypes.shape({
    title: PropTypes.string,
  }),
  idSchema: PropTypes.shape({
    $id: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  formData: PropTypes.arrayOf(PropTypes.string),
};

PermissionsMatrix.defaultProps = {
  schema: {},
  idSchema: {},
  formData: [],
};

export default PermissionsMatrix;
