import axios from 'axios';
import { getCommonHeaders } from '../common-api-utils';
import config from '../../config/config';

const {
  api,
  integrations: { sap },
} = config;

export async function submitToSAP(candidateId) {
  if (candidateId) {
    const result = await axios.post(
      `${api.middlewareAPIURL}${sap.basePath}/${candidateId}`,
      {},
      {
        headers: getCommonHeaders(),
      },
    );

    if (result.status === 201 || result.status === 200) return result.data;

    return null;
  }
}

export async function getSAPBasicSubmission(candidateId) {
  if (candidateId) {
    const result = await axios.get(`${api.middlewareAPIURL}${sap.basePath}/${candidateId}`, {
      headers: getCommonHeaders(),
    });

    if (result.status === 201 || result.status === 200) return result.data;

    return null;
  }
}

export async function getSAPSubmission(candidateId) {
  if (candidateId) {
    const result = await axios.get(`${api.middlewareAPIURL}${sap.submitPath}/${candidateId}`, {
      headers: getCommonHeaders(),
    });

    if (result.status === 201 || result.status === 200) return result.data;

    return null;
  }
}

export async function resendSAPSubmission(candidateId, data) {
  if (candidateId) {
    const result = await axios.post(`${api.middlewareAPIURL}${sap.resendPath}/${candidateId}`, data, {
      headers: getCommonHeaders(),
    });

    if (result.status === 201 || result.status === 200) return result.data;

    return null;
  }
}
