import { getTimeOptions } from '../../utils/dateTimeUtils';
import inviteInputConfig from './inviteInputConfig';

const timeOptions = getTimeOptions();

const eventInputConfig = {
  appointment: [
    {
      id: 'startDate',
      label: 'Date',
      type: 'date',
      required: true,
    },
    // {
    //   id: 'allDay',
    //   label: 'All Day',
    //   type: 'checkbox',
    // },
    {
      id: 'startTime',
      label: 'Start Time',
      type: 'select',
      required: true,
      options: timeOptions,
      // hideIfAllDay: true,
    },
    // {
    //   id: 'endDate',
    //   label: 'End Date',
    //   type: 'date',
    //   required: true,
    //   // hideIfAllDay: true,
    // },
    {
      id: 'endTime',
      label: 'End Time',
      type: 'select',
      required: true,
      options: timeOptions,
      // hideIfAllDay: true,
    },
    {
      id: 'slotDuration',
      label: 'Slot Duration',
      type: 'select',
      options: [
        // we add options in the eventInputs.js to filter out the options based on the start and end time
      ],
    },
    {
      id: 'recurrenceType',
      label: 'Recurring Event',
      type: 'select',
      required: true,
      disableOnEdit: true,
      options: [
        { value: 'null', label: 'No (default)' },
        { value: 'DAILY', label: 'Every Weekday' },
        { value: 'WEEKLY', label: 'Weekly' },
      ],
    },
    {
      id: 'recurrenceEndDate',
      label: 'Recurring End Date',
      type: 'date',
      showIfRecurring: true,
      required: true,
      disableOnEdit: true,
    },
  ],
  details: [
    {
      ...inviteInputConfig.eventType,
      displayIfLT2: true,
      inline: false,
    },
    {
      id: 'eventName',
      label: 'Event Name',
      type: 'text',
      required: true,
    },
    {
      id: 'locationId',
      label: 'Location',
      type: 'locationSelect',
      required: true,
      dynamicOptions: true,
    },
    {
      id: 'numSpaces',
      label: 'Maximum number of spaces',
      type: 'number',
      required: true,
      min: 1,
      max: 500,
    },
  ],
  invites: [
    {
      id: 'candidateIds',
      type: 'candidateSelect',
    },
    { ...inviteInputConfig.inviteType, hideIfRecurring: true, ignoreDisplayMode: true },
    { ...inviteInputConfig.emailDetails, ignoreDisplayMode: true },
    { ...inviteInputConfig.sendSms, ignoreDisplayMode: true },
    { ...inviteInputConfig.reminderDays },
  ],
  extras: [
    {
      id: 'inviteOnly',
      label: 'Event Visibility',
      type: 'radio',
      ignoreDisplayMode: true,
      options: [
        { id: 'public', value: 'false', label: 'Public' },
        { id: 'private', value: 'true', label: 'Private' },
      ],
      helpText: 'Making this event public will expose this slot to candidates meeting the filter criteria',
    },
    {
      id: 'emailAddresses',
      label: 'Notify email address(es) on event updates',
      type: 'emailAddresses',
      hideOnRead: true,
    },
    {
      id: 'eventDescription',
      label: 'Event Description',
      type: 'richtext',
    },
  ],
};

export default eventInputConfig;
