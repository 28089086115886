import React, { memo, useState } from 'react';
import ActionButtons from './ActionButtons';
import { SliderContext } from './SliderContext';

const NotesNode = ({ id, data }) => {
  const [isSliderOpen, setIsSliderOpen] = useState(false);

  const handleDoubleClick = () => {
    setIsSliderOpen(true);
  };

  return (
    <SliderContext.Provider value={{ isSliderOpen, setIsSliderOpen }}>
      <div onDoubleClick={handleDoubleClick}>
        <ActionButtons id={id} data={data} />
        <NodeResizer minWidth={100} minHeight={30} />
        <div style={{ padding: 10, wordBreak: 'break-all' }}>{data.label}</div>
      </div>
    </SliderContext.Provider>
  );
};

export default memo(NotesNode);
