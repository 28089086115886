import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, FormFeedback } from 'reactstrap';
import { toast } from 'react-toastify';
import ReactQuill, { Quill } from 'react-quill';
import { checkPermissions } from '../../../js/auth/AuthUtils';

const SizeStyle = Quill.import('attributors/style/size');
SizeStyle.whitelist = ['9px', '12px', '18px', '30px'];
Quill.register(SizeStyle, true);

const font = Quill.import('attributors/style/font');
font.whitelist = ['Georgia, Times New Roman, serif', 'Monaco, Courier New, monospace'];
Quill.register(font, true);

const DD_ID = 'personalisation';
const DD_LABEL = 'Personalisation';

function fileWarning() {
  const input = document.querySelector('.ql-image[type="file"]');
  const files = input ? input.files : [];

  if (files.length) {
    const totalSize = [...files].reduce((acc, { size = 0 }) => acc + size, 0);
    if (totalSize > 1000000) {
      // eslint-disable-next-line max-len
      toast.error(
        "Sorry the images you've included should be no more than 1MB in size. Please reduce the image size and try again",
      );
    }
  }
}

function getPersonalisationDropdown() {
  const options = [
    'ATS User Name',
    'Applicant First Name',
    'Applicant Surname',
    'Application Url',
    'Calendar Invite Link',
    'Calendar Invite Location',
    'Calendar Event Date',
    'Calendar Event Time',
    'Calendar Event Name',
    'Calendar Event Description',
    'EDocument Urls',
    'Employer Email Address',
    'Employer Name',
    'FlexiForm Urls',
    'Form Url',
    'TalentHub Login Url',
    'TalentHub Action Url',
    'TalentHub Url',
    'Vacancy Location',
    'Vacancy Postcode',
    'Vacancy Reference',
    'Vacancy Title',
    'Video Interview Link',
  ];

  if (checkPermissions(['dashboard:view'])) {
    options.push('TalentHub Login Url');
  }
  return options.sort((a, b) => a.localeCompare(b));
}
class EmailBodyInput extends Component {
  constructor(props) {
    super(props);
    const { value } = props;
    this.state = { value };
    this.editor = React.createRef();
  }

  componentDidMount() {
    const dropDownItems = [...document.querySelectorAll(`.ql-${DD_ID} .ql-picker-item`)];
    dropDownItems.forEach((item) => {
      // eslint-disable-next-line no-param-reassign
      item.textContent = item.dataset.value;
    });

    const dropDownLabel = document.querySelector(`.ql-${DD_ID} .ql-picker-label`);
    if (dropDownLabel) dropDownLabel.innerHTML = `${DD_LABEL} ${dropDownLabel.innerHTML}`;
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (prevProps.value !== value) {
      this.onUpdate(value);
    }
  }

  onUpdate = (value) => {
    this.setState({ value });
  };

  handleChange = (val, delta, source, editor) => {
    const { onChange } = this.props;
    fileWarning();
    this.setState({ value: val });

    const emptyContent = /^(<p><br><\/p>|<p><br \/><\/p>|<p>(\s+)?<\/p>)$/g.test(val);

    const value = emptyContent ? '' : val;

    onChange(value, editor.getText());
  };

  render() {
    const { isReadOnly, invalid, errorMsg } = this.props;
    const { value } = this.state;

    return (
      <FormGroup>
        <ReactQuill
          ref={this.editor}
          className={`message-body with-toolbar ${invalid ? 'is-invalid' : ''}`}
          modules={isReadOnly ? { toolbar: false } : EmailBodyInput.modules}
          onChange={this.handleChange}
          readOnly={isReadOnly}
          value={value || ''}
        />
        <FormFeedback>{errorMsg}</FormFeedback>
      </FormGroup>
    );
  }
}

/*
 * Quill modules to attach to editor
 * See http://quilljs.com/docs/modules/ for complete options
 */
EmailBodyInput.modules = {
  toolbar: {
    container: [
      [{ font: [false, 'Georgia, Times New Roman, serif', 'Monaco, Courier New, monospace'] }],
      [{ size: ['9px', false, '18px', '30px'] }],
      ['bold', 'italic', 'underline'],
      [{ list: 'bullet' }],
      ['link', 'image'],
      [
        {
          [DD_ID]: getPersonalisationDropdown(),
        },
      ],
    ],
    handlers: {
      [DD_ID](value) {
        if (value) {
          const modifiedValue = `{${value.toLowerCase().replace(/ /g, '_')}}`;
          const cursorPosition = this.quill.getSelection().index;
          this.quill.insertText(cursorPosition, modifiedValue);
          this.quill.setSelection(cursorPosition + modifiedValue.length);
        }
      },
      image() {
        const { tooltip } = this.quill.theme;
        const originalSave = tooltip.save;
        const originalHide = tooltip.hide;

        // eslint-disable-next-line func-names
        tooltip.save = function () {
          const range = this.quill.getSelection(true);
          const { value } = this.textbox;
          if (value) {
            this.quill.insertEmbed(range.index, 'image', value, Quill.sources.USER);
          }
        };

        // Called on hide and save.
        tooltip.hide = () => {
          tooltip.save = originalSave;
          tooltip.hide = originalHide;
          tooltip.hide();
        };

        tooltip.edit('image');
      },
    },
  },
};

EmailBodyInput.propTypes = {
  value: PropTypes.string,
  invalid: PropTypes.bool,
  errorMsg: PropTypes.string,
  onChange: PropTypes.func,
  isReadOnly: PropTypes.bool,
};

EmailBodyInput.defaultProps = {
  value: '',
  invalid: false,
  errorMsg: null,
  onChange: () => {},
  isReadOnly: false,
};

export default EmailBodyInput;
