import 'core-js/stable';
import 'core-js/features/array/at';
import 'core-js/features/promise/all-settled';
import 'core-js/features/promise/with-resolvers';
import 'regenerator-runtime/runtime';
// eslint-disable-next-line no-unused-vars
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Routes, Navigate } from 'react-router-dom';
import { toast, ToastContainer, Flip } from 'react-toastify';
import './App.scss';
// Containers
import { datadogRum } from '@datadog/browser-rum';
// routes config
import ReactGA from 'react-ga4';
import routes from './routes';
import withRouter from './hoc/withRouter';
import config from './config/config';
import { Page404, Page500, Register, ATR } from './views/Pages';
import Login from './views/Pages/Login/Login';
import Page403 from './views/Pages/Page403/Page403';
import { isAuthenticated, doLogout, loadUserListScript } from './js/auth/AuthUtils';
import ForgotPasswordEmail from './views/Pages/ForgotPassword/ForgotPasswordEmail/ForgotPasswordEmail';
import ForgotPasswordChange from './views/Pages/ForgotPassword/ForgotPasswordChange/ForgotPasswordChange';
import { PrivateRoute } from './views/Base/Auth';
import { batchUpdateCssVars } from './views/Base/hooks';
import { setVarsObject } from './js/utils/style-utils';
import { addZendesk } from './js/utils/add-script';
import { SiteProxy } from './proxy';
import Page503 from './views/Pages/Page503/Page503';
import ExternalCandidateView from './views/Applications/External/ExternalCandidateView';
import TwoFAChallenge from './views/Pages/Login/TwoFAChallenge';
import IDPAuthResponse from './views/Pages/Login/IDPAuthResponse';
import Pending from './views/Pages/Login/Pending';
import VersionChecker from './views/Base/Version/VersionChecker';
import PasswordChangeRequired from './views/Pages/Login/PasswordChangeRequired';
import SyncCallBackView from './views/Scheduler/SyncCallBackView';

const { datadog, ga } = config;

datadogRum.init({
  applicationId: datadog.applicationId,
  clientToken: datadog.clientToken,
  defaultPrivacyLevel: 'mask',
  enableExperimentalFeatures: ['clickmap'],
  env: config.env,
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sampleRate: datadog.sampleRate,
  service: 'ats-dashboard',
  sessionReplaySampleRate: datadog.sampleRate,
  site: 'datadoghq.eu',
  trackInteractions: true,
  trackLongTasks: true,
  trackResources: true,
  trackUserInteractions: true,
});

datadogRum.startSessionReplayRecording();

ReactGA.initialize(ga.measurementId);

class App extends Component {
  componentDidMount() {
    const { styles, ulToken } = this.props;
    batchUpdateCssVars(setVarsObject(styles));
    if (ulToken) {
      loadUserListScript(ulToken);
    }

    const t = setInterval(async () => {
      const isAuthed = await isAuthenticated();
      if (!isAuthed) {
        clearInterval(t);
        await doLogout();
      }
    }, 600000);

    addZendesk();
  }

  render() {
    return (
      <>
        <VersionChecker />
        <Routes>
          <Route element={<Login />} name="Login Page" path="/login" />
          <Route element={<Pending />} name="Account Pending Page" path="/pending" />
          <Route element={<TwoFAChallenge />} name="2FA Challenge Page" path="/2FA" />
          <Route
            element={<PasswordChangeRequired />}
            name="Password Change Required"
            path="/password-change-required"
          />
          <Route element={<Register />} name="Register Page" path="/register2938474" />
          <Route element={<ATR />} name="ATR Page" path="/atr/authorise" />
          <Route element={<ForgotPasswordEmail />} name="Recover Password" path="/password-recovery" />
          <Route element={<ForgotPasswordChange />} name="Reset Password" path="/reset-password" />
          <Route element={<Page404 />} name="Page 404" path="/404" />
          <Route element={<Page403 />} name="Page 403" path="/403" />
          <Route element={<Page500 />} name="Page 500" path="/500" />
          <Route element={<Page503 />} name="Maintenance" path="/maintenance" />
          <Route element={<ExternalCandidateView />} name="External Candidate" path="/external/candidate" />
          <Route element={<IDPAuthResponse />} name="IDP Login Response" path="/openid/auth" />
          <Route element={<SyncCallBackView />} name="Calendar Sync Callback" path="/schedule/sync/cb" />
          <Route element={<PrivateRoute />} path="/">
            {routes.map((route, idx) => {
              return route.element ? (
                <Route
                  // eslint-disable-next-line react/no-array-index-key
                  key={idx}
                  element={
                    <SiteProxy>
                      <route.element />
                    </SiteProxy>
                  }
                  name={route.name}
                  path={route.path}
                />
              ) : null;
            })}
          </Route>
          <Route element={<Navigate to="/login" />} path="*" />
        </Routes>
        <ToastContainer draggable={false} hideProgressBar icon={false} position={'bottom-center'} transition={Flip} />
      </>
    );
  }
}

App.propTypes = {
  styles: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.shape()])),
};

App.defaultProps = {
  styles: {},
};

function mapStateToProps(state) {
  const { styles, userData } = state;
  // no need for logo in css
  const { logoUrl, ...rest } = styles;
  const { ulToken } = userData?.userAuth;
  return { styles: rest, ulToken };
}

export default withRouter(connect(mapStateToProps)(App));
