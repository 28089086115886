import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CancelButton, CreateButton } from '../../../Base/Buttons';
import { Form, Input } from 'reactstrap';
import { FormGroup } from '../../../Base/Forms/Custom/CommonComponents';
import { AccountFormSelect } from '../../../Base/Account';
import { useFormKeypress } from '../../../Base/hooks';
import { requestStatuses } from '../../../../js/constants/requestStatuses';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import CatagoriesDropdown from './CatagoriesDropdown';
import Select from 'react-select';
import UserSelect from './UserSelect';
import { retryableAPICall } from '../../../../api/common-api-utils';
import { saveATRUser } from '../../../../api/ATRAPI/ATRAPI';
import { trimFormData } from '../../../../js/utils/general-utils';
import validation, { mapErrors } from '../../../../js/utils/validation';

const capitalize = (s) => (s && s[0].toUpperCase() + s.slice(1).toLowerCase()) || '';

const defaultClientData = {
  name: '',
  authorisers: [],
  categories: [],
  vacancyTypes: [],
  sharedWith: [],
  id: null,
};

function EditPanel({ isEditing, data, onSave, onCancel, activeAccountId, activeAccountName, totalAccounts }) {
  const formRef = useFormKeypress();
  const [isSaving, setIsSaving] = useState(false);
  const [clientData, setClientData] = useState({ ...defaultClientData });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const { sharedWith, readOnly, ...rest } = data;

    const categories = (data.categories || []).map((val) => ({ label: val, value: val }));
    const vacancyTypes = (data.vacancyTypes || []).map((val) => ({ label: capitalize(val), value: val }));
    const authorisers = (data.authorisers || []).map((val) => ({ value: val.userId, label: val.userName }));

    setClientData((prevState) => ({
      ...prevState,
      ...rest,
      categories,
      vacancyTypes,
      authorisers,
      sharedWith: sharedWith || [],
      readOnly: readOnly || false,
    }));
  }, [activeAccountId, data]);

  function handleChange(id, value) {
    let updated = { ...clientData, [id]: value };

    setClientData(updated);
  }

  async function handleSave() {
    setIsSaving(true);

    if (clientData.id === null) {
      delete clientData.id;
    }
    const trimmedData = trimFormData(clientData);
    setClientData(trimmedData);

    const baseErrObj = validation(
      [
        { id: 'name', required: true },
        { id: 'authorisers', required: true },
        { id: 'categories', required: true },
        { id: 'vacancyTypes', required: true },
      ],
      trimmedData,
    );

    const { messages, hasErrors } = mapErrors(baseErrObj);
    setErrors(messages);

    if (!hasErrors) {
      const { sharedWith, actions, categories, vacancyTypes, authorisers, ...rest } = trimmedData;

      const sharedWithIds = [
        ...(sharedWith || []),
        { accountId: activeAccountId, accountName: activeAccountName },
      ].filter(({ accountId }) => accountId);

      const serverData = {
        name: rest.name,
        categories: categories.map(({ value }) => value),
        vacancyTypes: vacancyTypes.map(({ value }) => value),
        authorisers: authorisers.map(({ value, label }) => ({ userId: value, userName: label })),
        sharedWith: sharedWithIds,
      };

      const resp = await retryableAPICall(() => saveATRUser(serverData, data.id));

      if (typeof resp === 'string') {
        let reasonStr = '';
        if (isEditing) {
          if (resp === requestStatuses.NOT_FOUND_ERROR) {
            reasonStr = '. Could not update atr';
          } else if (resp === requestStatuses.PERMISSION_DENIED) {
            reasonStr = '. You do not have permission to edit this atr';
          }
        }

        if (resp === requestStatuses.ALREADY_EXISTS_ERROR) {
          reasonStr = '. Unable to create atr as this name has already been used';
        }

        toast.error(`Error ${isEditing ? 'updating' : 'creating'} atr${reasonStr}`);
      } else {
        toast.success(`ATR successfully ${isEditing ? 'updated' : 'created'}`);
        onSave(resp);
      }
    }

    setIsSaving(false);
  }

  const saveBtnText = isSaving ? 'Saving...' : `${isEditing ? 'Update' : 'Create'} ATR`;

  return (
    <Form
      className="atr-editor"
      innerRef={formRef}
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <FormGroup error={errors.name} id="name" label="Name" required>
        <Input
          id="name"
          onChange={(e) => handleChange('name', e.target.value)}
          type="text"
          value={clientData.name || ''}
        />
      </FormGroup>
      <FormGroup error={errors.categories} id="categories" label="Categories" required>
        <CatagoriesDropdown
          id="categories"
          onChange={(value) => handleChange('categories', value)}
          type="text"
          value={clientData.categories || []}
        />
      </FormGroup>
      <FormGroup error={errors.vacancyTypes} id="vacancyTypes" label="Vacancy Types" required>
        <Select
          classNamePrefix="react-select"
          placeholder="Select Form"
          className="flex-grow-1"
          isMulti
          value={clientData.vacancyTypes}
          options={[
            { value: 'NEW', label: 'New' },
            { value: 'BACKFILL', label: 'Backfill' },
          ]}
          onChange={(vacancyType) => {
            handleChange('vacancyTypes', vacancyType);
          }}
        />
      </FormGroup>
      {totalAccounts > 1 && (
        <AccountFormSelect
          className="account-opts"
          classNamePrefix="react-select"
          currentAccounts={(clientData.sharedWith || []).map(({ accountId, accountName, readOnly }) => ({
            id: accountId,
            name: accountName,
            readOnly: accountId === data.accountId || readOnly,
          }))}
          id="sharedWith"
          isMulti
          label="Shared With"
          onChange={(accountObjs = []) => {
            const accounts = accountObjs.map(({ value, label, readOnly }) => ({
              accountId: value,
              accountName: label,
              readOnly,
            }));
            handleChange('sharedWith', accounts);
          }}
        />
      )}
      <FormGroup error={errors.authorisers} id="name" label="Authorisers" required>
        <UserSelect
          currentAssignment={clientData.authorisers}
          onChange={(authorisers) => {
            setClientData({ ...clientData, authorisers });
          }}
        />
      </FormGroup>
      <CreateButton action={handleSave} disabled={isSaving} isLoading={isSaving} label={saveBtnText} />
      <CancelButton action={onCancel} disabled={isSaving} label="Cancel" />
    </Form>
  );
}

EditPanel.propTypes = {
  activeAccountId: PropTypes.string,
  activeAccountName: PropTypes.string,
  data: PropTypes.shape(),
  isEditing: PropTypes.bool,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  totalAccounts: PropTypes.number,
};

EditPanel.defaultProps = {
  activeAccountId: undefined,
  activeAccountName: undefined,
  data: {},
  isEditing: false,
  onCancel: () => {},
  onSave: () => {},
  totalAccounts: 0,
};

function mapStateToProps(state) {
  const {
    userData: {
      userDetails: {
        activeAccountId,
        activeAccountName,
        data: { accountAccess = [] },
      },
    },
  } = state;

  return { activeAccountId, activeAccountName, totalAccounts: accountAccess.length };
}

export default connect(mapStateToProps)(EditPanel);
