export function getNextId(nodes) {
  if (nodes.length === 0) return '0';
  return String(Math.max(...nodes.map((node) => parseInt(node.id, 10))) + 1);
}

export function findClosestNode(position, nodes) {
  let closestNode = null;
  let closestDistance = Infinity; // Initialize with a large number

  nodes.forEach((node) => {
    const nodeBounds = {
      x: node.position.x,
      y: node.position.y,
      width: node.measured?.width,
      height: node.measured?.height,
    };

    // Calculate the center of the node
    const nodeCenter = {
      x: nodeBounds.x + nodeBounds.width / 2,
      y: nodeBounds.y + nodeBounds.height / 2,
    };

    // Calculate the Euclidean distance from the position to the node center
    const distance = Math.sqrt(Math.pow(position.x - nodeCenter.x, 2) + Math.pow(position.y - nodeCenter.y, 2));

    // Check if position is within the expanded bounds (100px from each side)
    const isWithinBounds =
      position.x >= nodeBounds.x - 100 &&
      position.x <= nodeBounds.x + nodeBounds.width + 100 &&
      position.y >= nodeBounds.y - 100 &&
      position.y <= nodeBounds.y + nodeBounds.height + 100;

    // If within bounds and the distance is less than the closestDistance, update closestNode
    if (isWithinBounds && distance < closestDistance) {
      closestNode = node;
      closestDistance = distance; // Update the closest distance
    }
  });

  return closestNode;
}

function getStartNodeFiltersToServer(nodeInfo) {
  let filters = {};

  switch (nodeInfo?.actionType) {
    case 'SEND_CONTACT_TEMPLATE_EMAIL':
    case 'SEND_EMAIL':
    case 'GENERIC_FORM_STATUS_CHANGE':
    case 'ONBOARDING_STATUS_CHANGE':
    case 'EDOC_STATUS_CHANGE':
    case 'REFEREE_STATUS_CHANGE':
    case 'DBS_STATUS_CHANGE':
    case 'REFERENCE_STATUS_CHANGE':
    case 'PAYROLL_INTEGRATION_SUBMISSION':
    case 'RTW_STATUS_CHANGE':
      filters = [{ field: 'status', operation: 'EQ', value: [nodeInfo.condition] }];
      break;
    case 'CREATE_CANDIDATE_EVENT':
      let tempFiler = [
        { field: 'status', operation: 'EQ', value: nodeInfo.condition?.value },
        { field: 'outcome', operation: 'EQ', value: nodeInfo.condition?.value },
      ];

      if (
        nodeInfo.condition?.value === 'UTMMEDIUM' ||
        nodeInfo.condition?.value === 'UTMCAMPAIGN' ||
        nodeInfo.condition?.value === 'UTMSOURCE'
      ) {
        tempFiler.push({ field: 'candidateFilterVal', operation: 'EQ', value: nodeInfo.candidateFilterVal });
      } else {
        tempFiler.push({ field: 'funnelData.funnelId', operation: 'IN', value: [nodeInfo.funnelId] });
      }
      filters = tempFiler;
      break;
    case 'EVENT_STATUS_CHANGE':
      filters = [
        { field: 'eventTypeId', operation: 'IN', value: [nodeInfo.eventType] },
        { field: 'status', operation: 'EQ', value: nodeInfo.condition },
      ];
      break;
    case 'FUNNEL_STATE_CHANGE':
      filters = [
        { field: 'funnelData.funnelId', operation: 'IN', value: [nodeInfo.funnelId] },
        { field: 'funnelData.stageId', operation: 'IN', value: [nodeInfo.stageId] },
      ];
      break;
    case 'PERSONALITY_TEST_STATUS_CHANGE':
      filters = [{ field: 'status', operation: 'EQ', value: nodeInfo.condition }];

      if (nodeInfo.condition === 'RECEIVED') {
        filters.push({ field: 'operator', operation: 'EQ', value: nodeInfo.operator.value });

        if (nodeInfo.operator.value !== 'ALL') {
          filters.push(
            { field: 'score', operation: 'EQ', value: nodeInfo.scoreValue },
            { field: 'scoreType', operation: 'EQ', value: nodeInfo.scoreType.value },
          );
        }
      }
      break;
    default:
      filters = {};
      break;
  }

  return filters;
}

function convertTime(number, range) {
  let timeInMinutes = 0;

  if (range === 'min') {
    timeInMinutes = number * 60;
  } else if (range === 'hour') {
    timeInMinutes = number * 60 * 60;
  } else if (range === 'day') {
    timeInMinutes = number * 60 * 60 * 24;
  }

  return timeInMinutes;
}

export function convertWorkflowToServer(workflow) {
  const { nodes, edges, name, activeAccountId, sharedWith, exitCondtions, status } = workflow;

  const startNode = nodes.find((node) => node.type === 'startNode');

  const convertedWorkflow = {
    name: name,
    accountId: activeAccountId,
    type: startNode?.data?.actionType,
    sharedWith: sharedWith,
    stages: [],
    dimensions: {
      w: startNode.measured.width,
      h: startNode.measured.height,
    },
    coords: startNode.position,
    edges: edges,
    filters: getStartNodeFiltersToServer(startNode.data),
    status: status,
  };

  if (exitCondtions) {
    convertedWorkflow.exitCondtions = [
      {
        condition: { field: 'latestFunnelStage.stageType', operation: 'IN', value: exitCondtions },
        evaluatorType: 'CANDIDATE',
      },
    ];
  }

  const filteredNodes = nodes.filter((node) => node.type !== 'startNode');

  filteredNodes.forEach((node) => {
    const { label, actionType, id, ...rest } = node.data;

    let stage = {
      name: label,
      label: label,
      stageNo: parseInt(node.id),
      type: actionType,
      delay: 0,
      props: {
        ...rest,
      },
      actionOnFail: 'END',
      dimensions: {
        w: node.measured.width,
        h: node.measured.height,
      },
      coords: node.position,
    };

    if (actionType === 'WAIT') {
      stage = {
        ...stage,
        props: {
          ...stage.props,
          delay: convertTime(stage.props.number, stage.props.range),
        },
      };
    }
    convertedWorkflow.stages.push(stage);
  });

  return convertedWorkflow;
}

function getStartNodeFiltersFromServer(filters, actionType) {
  let nodeInfo = {};

  switch (actionType) {
    case 'SEND_CONTACT_TEMPLATE_EMAIL':
    case 'SEND_CANDIDATE_CONTACT_EMAIL':
    case 'GENERIC_FORM_STATUS_CHANGE':
    case 'ONBOARDING_STATUS_CHANGE':
    case 'EDOC_STATUS_CHANGE':
    case 'REFEREE_STATUS_CHANGE':
    case 'DBS_STATUS_CHANGE':
    case 'REFERENCE_STATUS_CHANGE':
    case 'PAYROLL_INTEGRATION_SUBMISSION':
    case 'RTW_STATUS_CHANGE': {
      let status = '';

      const statusEQFilter = filters.find((filter) => filter.field === 'status' && filter.operation === 'EQ');
      const statusINFilter = filters.find((filter) => filter.field === 'status' && filter.operation === 'IN');

      if (statusEQFilter) {
        status = statusEQFilter.value;
      } else if (statusINFilter) {
        status = statusINFilter.value[0];
      }

      nodeInfo.condition = statusINFilter || statusEQFilter ? status : '';
      break;
    }

    case 'CREATE_CANDIDATE_EVENT': {
      const funnelIdFilter = filters.find(
        (filter) => filter.field === 'funnelData.funnelId' && filter.operation === 'IN',
      );

      const funnelUTMCampaignFilter = filters.find(
        (filter) => filter.field === 'utmCampaign' && filter.operation === 'IN',
      );

      debugger;
      const funnelUTMSourceFilter = filters.find((filter) => filter.field === 'utmSource' && filter.operation === 'IN');

      const funnelUTMMediumFilter = filters.find((filter) => filter.field === 'utmMedium' && filter.operation === 'IN');

      if (funnelIdFilter) {
        const outcomeFilter = filters.find((filter) => filter.field === 'outcome' && filter.operation === 'EQ');
        const statusFilter = filters.find((filter) => filter.field === 'status' && filter.operation === 'EQ');

        nodeInfo.funnelId = funnelIdFilter && funnelIdFilter.value[0] ? funnelIdFilter.value[0] : '';
        nodeInfo.condition = {
          value: outcomeFilter ? outcomeFilter.value[0] : statusFilter ? statusFilter.value : '',
        };
      } else if (funnelUTMCampaignFilter) {
        nodeInfo.candidateFilterVal = funnelUTMCampaignFilter.value[0];
        nodeInfo.condition = {
          value: 'UTMMEDIUM',
        };
      } else if (funnelUTMSourceFilter) {
        nodeInfo.candidateFilterVal = funnelUTMSourceFilter.value[0];
        nodeInfo.condition = {
          value: 'UTMSOURCE',
        };
      } else if (funnelUTMMediumFilter) {
        nodeInfo.candidateFilterVal = funnelUTMMediumFilter.value[0];
        nodeInfo.condition = {
          value: 'UTMMEDIUM',
        };
      }

      break;
    }

    case 'EVENT_STATUS_CHANGE': {
      const eventTypeFilter = filters.find((filter) => filter.field === 'eventTypeId' && filter.operation === 'IN');
      const statusFilter = filters.find((filter) => filter.field === 'status' && filter.operation === 'EQ');

      nodeInfo.eventType = eventTypeFilter && eventTypeFilter.value[0] ? eventTypeFilter.value[0] : '';
      nodeInfo.condition = statusFilter ? statusFilter.value : '';
      break;
    }

    case 'FUNNEL_STATE_CHANGE': {
      const funnelIdFilter = filters.find(
        (filter) => filter.field === 'funnelData.funnelId' && filter.operation === 'IN',
      );
      const stageIdFilter = filters.find(
        (filter) => filter.field === 'funnelData.stageId' && filter.operation === 'IN',
      );

      nodeInfo.funnelId = funnelIdFilter && funnelIdFilter.value[0] ? funnelIdFilter.value[0] : '';
      nodeInfo.stageId = stageIdFilter && stageIdFilter.value[0] ? stageIdFilter.value[0] : '';
      break;
    }

    case 'PERSONALITY_TEST_STATUS_CHANGE': {
      const statusFilter = filters.find((filter) => filter.field === 'status' && filter.operation === 'EQ');
      nodeInfo.condition = statusFilter ? statusFilter.value : '';

      if (nodeInfo.condition === 'RECEIVED') {
        const operatorFilter = filters.find((filter) => filter.field === 'operator' && filter.operation === 'EQ');
        nodeInfo.operator = { value: operatorFilter ? operatorFilter.value : '' };

        if (nodeInfo.operator.value !== 'ALL') {
          const scoreFilter = filters.find((filter) => filter.field === 'score' && filter.operation === 'EQ');
          const scoreTypeFilter = filters.find((filter) => filter.field === 'scoreType' && filter.operation === 'EQ');

          nodeInfo.scoreValue = scoreFilter ? scoreFilter.value : '';
          nodeInfo.scoreType = { value: scoreTypeFilter ? scoreTypeFilter.value : '' };
        }
      }
      break;
    }

    default:
      // Handle other cases if necessary
      break;
  }

  return nodeInfo;
}

export function convertWorkflowFromServer(convertedWorkflow) {
  const { name, accountId, type, sharedWith, stages, dimensions, coords, edges, filters, id, exitCondtions, status } =
    convertedWorkflow;

  const nodes = [];

  // Reconstruct the start node
  const startNodeData = {
    actionType: type,
    ...getStartNodeFiltersFromServer(filters, type),
  };

  const startNode = {
    type: 'startNode',
    data: startNodeData,
    measured: {
      width: dimensions?.w || 50,
      height: dimensions?.h || 50,
    },
    position: coords,
    id: '0',
    selected: false,
  };

  nodes.push(startNode);

  // Reconstruct other nodes
  stages.forEach((stage) => {
    const { label, type: actionType, props, dimensions: stageDimensions, coords: stageCoords, stageNo } = stage;

    const nodeData = {
      label,
      actionType,
      ...props,
    };
    let nodeType = 'actionNode';

    if (actionType === 'WAIT') {
      nodeData.number = props.number;
      nodeData.range = props.range;
      nodeType = 'waitNode';
    }

    if (actionType === 'EXIT') {
      nodeType = 'endNode';
    }

    const node = {
      data: nodeData,
      type: nodeType,
      id: stageNo.toString(),
      measured: {
        width: stageDimensions?.w || 50,
        height: stageDimensions?.h || 50,
      },
      position: stageCoords,
      selected: false,
    };

    nodes.push(node);
  });

  let funnelStageExitConditions = [];

  if (exitCondtions) {
    exitCondtions?.forEach((condition) => {
      if (condition?.condition?.field === 'latestFunnelStage.stageType') {
        if (condition?.condition?.operation === 'IN') {
          funnelStageExitConditions = condition?.condition?.value;
        } else if (condition?.condition?.operation === 'EQ') {
          funnelStageExitConditions = [condition?.condition?.value];
        }
      }
    });
  }

  const workflow = {
    nodes,
    edges,
    name,
    activeAccountId: accountId,
    sharedWith,
    id,
    exitCondtions: funnelStageExitConditions,
    status,
  };

  return workflow;
}
